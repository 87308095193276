import React from 'react'
import './dataTable.scss'

const DataTable = ({ data, header, topCaption, bottomCaption }) => {
  // console.log('DataTable', data)
  let tableHeader = header ?? Object.keys(data[0])
  let headerElems = tableHeader.map((item, i) => {
    let elem
    if (typeof item !== 'object') {
      elem = <th key={i}>{item}</th>
    } else if (Object.keys(item).includes('colspan')) {
      elem = <th key={i} colSpan={item.colspan}>{item.name}</th>
    }
    return elem
  })

  let tableElems

  if (typeof data[0] !== 'object') {

    tableElems = <tr>
      {data.map((item, i) => {
        return (<td key={i}>{item.toLocaleString()}</td>)
      })}
    </tr>
  } else {
    tableElems = data.map((item, i) => {
      return (<tr key={i}>
        {Object.values(item).map((value, i) => {
          return (<td key={i}>{value.toLocaleString()}</td>)
        })}
      </tr>)
    }
    )
  }

  return (
    <table className='data-table'>
      {!!topCaption && <caption style={{ textAlign: 'left' }}>{topCaption}</caption>}
      <thead>
        <tr>
          {headerElems}
        </tr>
      </thead>
      <tbody>
        {tableElems}
      </tbody>
      {!!bottomCaption && <tfoot >
        <tr>
          <td style={{ textAlign: 'left' }} colSpan={tableHeader.length}>
            {bottomCaption}
          </td></tr></tfoot>}
    </table>
  )
}

export default DataTable