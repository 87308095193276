import './homepage.scss'

const About = () => {
    return (
        <section className='homepage-container about'>
            {/* <h2>About</h2> */}
            <p>NJ Public Health Adapt was developed to support efforts at the state, regional and local levels to understand the impacts the changing climate conditions may have on public health as well as to support efforts to adapt to changing climate conditions in ways that improve health outcomes, especially for those populations experiencing climate-related health disparities.  NJ Health Adapt is expected to be a resource for many different end users including:</p>
            <ul>
                <li>
                    Regional, county, and local agencies such as those engaged in hazard mitigation and climate change resilience efforts;
                </li>
                <li>
                    Municipalities that seek to incorporate health into their compliance with 2021 amendments to the Municipal Land Use Law requiring consideration of natural hazards as part of land use planning;
                </li>
                <li>
                    Public health professionals including those involved in development of County Health Improvement Plans and delivery of local public health services;
                </li>
                <li>Hospitals and health systems that lead the development Community Health Needs Assessments,</li>

                <li>Community-based organizations that seek to improve health outcomes and disparities that may be exacerbated by climate conditions.</li>
            </ul>
            <p>This initial version of NJ Public Health Adapt has been developed by the Climate Change Resource Center at Rutgers University based on input received from the state's public health community.  This initial version will be undergoing testing with public health end users with a revised version expected in the coming months. </p>
        </section>
    )
}
export default About