import { useSelector } from "react-redux"
import DataSection from "../../dataSection/DataSection"
import DataTable from "../../dataTable/DataTable"
import Chart from "../../Chart/Chart"
import calculateRaceData from "./calculateRaceData"

const RaceDemo = () => {

  const muni = useSelector(state => state.muni)
  const county = useSelector(state => state.county)

  let censusData = !!muni ? muni.censusData : county.censusData

  let raceDemo = calculateRaceData(censusData)
  let categories = Object.values(raceDemo)?.map(item => Object.values(item)[0])
  let chartData = Object.values(raceDemo)?.map(item => Number(Object.values(item)[2].slice(0, Object.values(item)[2].length - 1)))
  let hisp = raceDemo.latino
  delete raceDemo.latino
  console.log('raceDemo', hisp)

  let caption = <span>Total population: {Number(censusData.B01001_001E).toLocaleString()}</span>
  let tableHeader = ['', 'Population Estimate', 'Population Prevalence']
  let tableData = Object.values(raceDemo)
  let chartOptions = {
    chart: {

      type: 'column',
      width: 800,
      // margin: 0,
    },
    title: {
      text: ``,
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: 'percent'
      },

    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    series: [

      {
        name: "% of   population",
        type: "column",
        data: chartData,
        color: '#007fac'
      },
    ],
  }

  return (
    <DataSection
      // description='Other is defined as those on the census who marked "other race" or "two or more races".' 
      title={`Race Proportional Demographics ${process.env.REACT_APP_CENSUS_YEAR}`}>
      <Chart chartOptions={chartOptions} />
      <DataTable data={tableData} header={tableHeader} topCaption={caption} bottomCaption={<em>{hisp.pct} of the population ({hisp.num} individuals) identified as {hisp.label}</em>} />
    </DataSection>
  )
}
export default RaceDemo