const calculateCensusData = data => {
    // console.log('general demo raw data',data)
    let output = {
        under5: { label: 'Aged under 5', num: 0, pct: 0 },
        over65: { label: 'Aged 65 and older', num: 0, pct: 0 },
        over65Alone: { label: 'Living alone, aged 65 and older', num: 0, pct: 0 },
        allAlone: { label: 'Living alone, all ages', num: 0, pct: 0 },
        disabledAdults: { label: 'At least one disability, aged 18 to 64', num: 0, pct: 0 },
        workingAge: { label: 'Unemployed, aged 16 and older', num: 0, pct: 0 },
        foreign: { label: 'Foreign Born', num: 0, pct: 0 },
        belowPov: { label: 'Below Poverty Line', num: 0, pct: 0 },
        lessEnglish: { label: 'Speak English "Less than Well"', num: 0, pct: 0 },
        noGed: {
            label: 'Less than high school education', num: 0, pct: 0
        },
        ambulatory: {
            label: 'Ambulatory difficulty', num: 0, pct: 0
        },
        cognitive: { label: 'Cognitive difficulty', num: 0, pct: 0 },
        outdoor: { label: 'Works outdoors', num: 0, pct: 0 },
        noIns: { label: 'No health insurance', num: 0, pct: 0 },
        group: { label: 'In group quarters', num: 0, pct: 0 }

    }

    // Total population
    // output.totalPop = Number(data.B01001_001E).toLocaleString
    //Proportion of population below the age of 5			
    output.under5.num = (Number(data.B01001_003E) + Number(data.B01001_027E)).toLocaleString()
    output.under5.pct = ((Number(data.B01001_003E) + Number(data.B01001_027E)) / Number(data.B01001_001E) * 100).toFixed(0) + '%'

    // proportion of the population 65 or older
    output.over65.num = (Number(data.B01001_020E) + Number(data.B01001_021E) + Number(data.B01001_022E) + Number(data.B01001_023E) + Number(data.B01001_024E) + Number(data.B01001_025E) + Number(data.B01001_044E) + Number(data.B01001_045E) + Number(data.B01001_046E) + Number(data.B01001_047E) + Number(data.B01001_048E) + Number(data.B01001_049E)).toLocaleString()
    output.over65.pct = ((Number(data.B01001_020E) + Number(data.B01001_021E) + Number(data.B01001_022E) + Number(data.B01001_023E) + Number(data.B01001_024E) + Number(data.B01001_025E) + Number(data.B01001_044E) + Number(data.B01001_045E) + Number(data.B01001_046E) + Number(data.B01001_047E) + Number(data.B01001_048E) + Number(data.B01001_049E)) / Number(data.B01001_001E) * 100).toFixed(0) + '%'

    // aged 65 and older living alone
    output.over65Alone.num = (Number(data.B09020_015E) + Number(data.B09020_018E)).toLocaleString()
    output.over65Alone.pct = ((Number(data.B09020_015E) + Number(data.B09020_018E)) / Number(data.B01001_001E) * 100).toFixed(0) + '%'

    // all ages living alone
    output.allAlone.num = Number(data.B11001_008E).toLocaleString()
    output.allAlone.pct = ((Number(data.B11001_008E) / Number(data.B01001_001E)) * 100).toFixed(0) + '%'

    // adults with disabilities
    output.disabledAdults.num = (Number(data.C18108_007E) + Number(data.C18108_008E)).toLocaleString()
    output.disabledAdults.pct = (((Number(data.C18108_007E) + Number(data.C18108_008E)) / Number(data.C18108_006E)) * 100).toFixed(0) + '%'

    //Age 16+ Unemployed
    output.workingAge.num = Number(data.B23025_005E).toLocaleString()
    output.workingAge.pct = ((Number(data.B23025_005E) / Number(data.B23025_001E)) * 100).toFixed(0) + '%'

    //Foreign Born	
    output.foreign.num = Number(data.B05002_013E).toLocaleString()
    output.foreign.pct = (Number(data.B05002_013E) / Number(data.B01001_001E) * 100).toFixed(0) + '%'

    //Below Poverty Line
    output.belowPov.num = Number(data.B17001_002E).toLocaleString()
    output.belowPov.pct = (Number(data.B17001_002E) / Number(data.B17001_001E) * 100).toFixed(0) + '%'

    //Speak English “Less than Well”
    output.lessEnglish.num = (Number(data.B06007_005E) + Number(data.B06007_008E)).toLocaleString()
    output.lessEnglish.pct = ((Number(data.B06007_005E) + Number(data.B06007_008E)) / Number(data.B06007_001E) * 100).toFixed(0) + '%'

    //Less than High School Education
    output.noGed.num = Number(data.B06009_002E).toLocaleString()
    output.noGed.pct = (Number(data.B06009_002E) / Number(data.B06009_001E) * 100).toFixed(0) + '%'

    // Ambulatory Difficulty
    output.ambulatory.num = (Number(data.B18105_004E) + Number(data.B18105_007E) + Number(data.B18105_010E) + Number(data.B18105_013E) + Number(data.B18105_016E) + Number(data.B18105_020E) + Number(data.B18105_023E) + Number(data.B18105_026E) + Number(data.B18105_029E) + Number(data.B18105_032E)).toLocaleString()
    output.ambulatory.pct = ((Number(data.B18105_004E) + Number(data.B18105_007E) + Number(data.B18105_010E) + Number(data.B18105_013E) + Number(data.B18105_016E) + Number(data.B18105_020E) + Number(data.B18105_023E) + Number(data.B18105_026E) + Number(data.B18105_029E) + Number(data.B18105_032E)) / Number(data.B18105_001E) * 100).toFixed(0) + '%'

    // Cognitive Difficulty
    output.cognitive.num = (Number(data.B18104_004E) + Number(data.B18104_007E) + Number(data.B18104_010E) + Number(data.B18104_013E) + Number(data.B18104_016E) + Number(data.B18104_020E) + Number(data.B18104_023E) + Number(data.B18104_026E) + Number(data.B18104_029E) + Number(data.B18104_032E)).toLocaleString()
    output.cognitive.pct = ((Number(data.B18104_004E) + Number(data.B18104_007E) + Number(data.B18104_010E) + Number(data.B18104_013E) + Number(data.B18104_016E) + Number(data.B18104_020E) + Number(data.B18104_023E) + Number(data.B18104_026E) + Number(data.B18104_029E) + Number(data.B18104_032E)) / Number(data.B18104_001E) * 100).toFixed(0) + '%'

    // Outdoor work
    output.outdoor.num = (Number(data.C24010_025E) + Number(data.C24010_032E) + Number(data.C24010_031E) + Number(data.C24010_033E) + Number(data.C24010_037E) + Number(data.C24010_021E) + Number(data.C24010_036E) + Number(data.C24010_061E) + Number(data.C24010_068E) + Number(data.C24010_067E) + Number(data.C24010_069E) + Number(data.C24010_073E) + Number(data.C24010_057E) + Number(data.C24010_072E)).toLocaleString()
    output.outdoor.pct = ((Number(data.C24010_025E) + Number(data.C24010_032E) + Number(data.C24010_031E) + Number(data.C24010_033E) + Number(data.C24010_037E) + Number(data.C24010_021E) + Number(data.C24010_036E) + Number(data.C24010_061E) + Number(data.C24010_068E) + Number(data.C24010_067E) + Number(data.C24010_069E) + Number(data.C24010_073E) + Number(data.C24010_057E) + Number(data.C24010_072E)) / Number(data.C24010_001E) * 100).toFixed(0) + '%'
    // no health insurance
    output.noIns.num = (Number(data.B27001_005E) + Number(data.B27001_008E) + Number(data.B27001_011E) + Number(data.B27001_014E) + Number(data.B27001_017E) + Number(data.B27001_020E) + Number(data.B27001_023E) + Number(data.B27001_026E) + Number(data.B27001_029E) + Number(data.B27001_033E) + Number(data.B27001_036E) + Number(data.B27001_039E) + Number(data.B27001_042E) + Number(data.B27001_045E) + Number(data.B27001_048E) + Number(data.B27001_051E) + Number(data.B27001_054E) + Number(data.B27001_057E)).toLocaleString()
    output.noIns.pct = ((Number(data.B27001_005E) + Number(data.B27001_008E) + Number(data.B27001_011E) + Number(data.B27001_014E) + Number(data.B27001_017E) + Number(data.B27001_020E) + Number(data.B27001_023E) + Number(data.B27001_026E) + Number(data.B27001_029E) + Number(data.B27001_033E) + Number(data.B27001_036E) + Number(data.B27001_039E) + Number(data.B27001_042E) + Number(data.B27001_045E) + Number(data.B27001_048E) + Number(data.B27001_051E) + Number(data.B27001_054E) + Number(data.B27001_057E)) / Number(data.B27001_001E) * 100).toFixed(0) + '%'
    // in group quarters
    output.group.num = Number(data.B26001_001E).toLocaleString()
    output.group.pct = (Number(data.B26001_001E) / Number(data.B01001_001E) * 100).toFixed(0) + '%'

    return output

}

export default calculateCensusData