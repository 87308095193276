const calculateRaceData = data => {
    // console.log('data',data)
    let output = {
        native: { label: 'American Indian or Alaska Native', num: 0, pct: 0 },
        asian: { label: 'Asian', num: 0, pct: 0 },
        black: { label: 'Black or African American', num: 0, pct: 0 },
        pacific: { label: 'Native Hawaiian or Pacific Islander', num: 0, pct: 0 },
        other: { label: 'Some other race alone', num: 0, pct: 0 },
        multi: { label: 'Two or more races', num: 0, pct: 0 },
        white: { label: 'White', num: 0, pct: 0 },
        latino: { label: 'Hispanic or Latino', num: 0, pct: 0 },

    }

    // console.log('calc race data', data)


    output.latino.num = Number(data.B01001I_001E).toLocaleString()
    output.latino.pct = (Number(data.B01001I_001E) / Number(data.B01001_001E) * 100).toFixed(0) + '%'
    output.black.num = Number(data.B01001B_001E).toLocaleString()
    output.black.pct = (Number(data.B01001B_001E) / Number(data.B01001_001E) * 100).toFixed(0) + '%'
    output.native.num = Number(data.B01001C_001E).toLocaleString()
    output.native.pct = (Number(data.B01001C_001E) / Number(data.B01001_001E) * 100).toFixed(0) + '%'
    output.asian.num = Number(data.B01001D_001E).toLocaleString()
    output.asian.pct = (Number(data.B01001D_001E) / Number(data.B01001_001E) * 100).toFixed(0) + '%'
    output.pacific.num = Number(data.B01001E_001E).toLocaleString()
    output.pacific.pct = (Number(data.B01001E_001E) / Number(data.B01001_001E) * 100).toFixed(0) + '%'
    output.other.num = Number(data.B01001F_001E).toLocaleString()
    output.multi.num = Number(data.B01001G_001E).toLocaleString()
    output.other.pct = (Number(data.B01001F_001E) / Number(data.B01001_001E) * 100).toFixed(0) + '%'
    output.multi.pct = (Number(data.B01001G_001E) / Number(data.B01001_001E) * 100).toFixed(0) + '%'

    output.white.num = (Number(data.B02001_002E)).toLocaleString()
    output.white.pct = ((Number(data.B02001_002E)) / Number(data.B01001_001E) * 100).toFixed(0) + '%'

    return output

}

export default calculateRaceData