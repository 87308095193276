import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReportHeader from '../reportHeader/ReportHeader';
import DataSection from '../dataSection'
import { useNavigate } from 'react-router-dom';
import { setNavLoc } from '../../features/navLocSlice'
import handleMissingCounty from '../../tools/handleMissingCounty';
import "@esri/calcite-components/dist/components/calcite-loader";
import StdButton from '../stdButton/StdButton';
// import { selectMuni } from '../../features/muniSlice';
// import { selectCounty } from '../../features/countySlice';
import mapArray from './mapArray';
import createMapView from '../../tools/createMapView';
// import { FaFilePdf } from 'react-icons/fa';
// import handleDownloadPdf from '../../tools/handlePdfDownload';
// import InsetMap from '../insetMap/InsetMap';
// import DataTable from '../dataTable'
// import { CalciteLoader } from '@esri/calcite-components-react';
// import eph from '../../services/eph';

// ** as of April, 2023, `2020` was the most recent census data available for these variables except for blood pressure, which is from 2017.  Check https://ephtracking.cdc.gov/DataExplorer/ to see if more recent years are available. ** 

const ExistingHealth = () => {
  const mapRef = useRef(null)
  const pagesRef = {
    page1: useRef(),
    page2: useRef(),
    page3: useRef(),
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  let censusYear = `2020`

  const county = useSelector(state => state.county)
  const muni = useSelector(state => state.muni)
  const viewOpts = useSelector(state => state.viewOpts)
  let { zoom, extent } = viewOpts
  let freshViewOpts = useMemo(() => { return { zoom, extent } }, [extent, zoom])

  const [selectedTopic, setSelectedTopic] = useState(0) // default is asthma
  const [view, setView] = useState(null)

  // const [dataArray, setDataArray] = useState([])
  // const [popArray, setPopArray] = useState([])

  // let ephData = !!muni ? muni.ephData : county.ephData

  // console.log('existing health data', viewOpts)
  const handleSelectedTopic = (topic) => {
    setSelectedTopic(topic)
  }

  // useEffect(() => {
  //   if (
  //     typeof ephData === 'object' &&
  //     !Array.isArray(ephData) &&
  //     ephData !== null
  //   ) {
  //     if (muni) {
  //       dispatch(selectMuni(muni))
  //     } else if (county) {
  //       dispatch(selectCounty(county))
  //     }
  //   }
  // }, [dispatch, ephData, muni, county])

  useEffect(() => {
    let freshView = createMapView({ portalItem: { id: mapArray[selectedTopic].portalId } }
      , freshViewOpts)
    setView(freshView)
  }, [selectedTopic, freshViewOpts])

  useEffect(() => {
    if (mapRef.current && view) {
      view.container = mapRef.current
    }
  }, [view])

  useEffect(() => {
    handleMissingCounty(county, navigate, dispatch, setNavLoc)
  }, [county, dispatch, navigate])


  return !!county && (
    <main className='page-container'>
      <div ref={pagesRef.page1}>
        <ReportHeader title='Existing Health Conditions Potentially Exacerbated by Climate Change' county={county.NAME} muni={!!muni ? muni.MUN_LABEL : null} />
      </div>

      <DataSection ref={pagesRef.page2} title={`Self Reported Environmental Public Health Variables Among Adults ${censusYear}*`}
        description={<>
          <p>Changing climate conditions can exacerbate existing health conditions, such as heart disease, respiratory conditions such as asthma, and diabetes. Some medical conditions, such as obesity and heart disease, increase people's sensitivity to heat, putting them at greater risk of heat illnesses. Increases in temperature can increase ground level ozone that exacerbates respiratory conditions. Living with existing health conditions can affect a person's ability to adapt to changing climate conditions. People with limited access to quality healthcare services may also be disproportionately affected by climate change.</p><p> Data sourced from the CDC's <a href='https://ephtracking.cdc.gov/' target={'_blank'} rel={'noreferrer'}>National Environmental Public Health Tracking Network</a>. The census bureau aggregates Behavioral Risk Factor Surveillance System (BRFSS) data and provides them at the census tract. The data are aggregated again to be reported by municipalities that intersect or contain those census tracts. These data are an estimate and the New Jersey Public Health Department should be contacted for more accurate information.</p>

          <p>BRFSS data are self-reported and reflect the perceptions of respondents. An advantage of self-reports is that they can reveal information that cannot be obtained from other sources; for example, the receipt of flu vaccinations for people who do not see a doctor during the year. A disadvantage of self-report data is that respondents may have difficulty recalling events, understanding or interpreting questions, or responding truthfully to questions. Furthermore, cultural and language barriers and limited health knowledge can affect the quality of self-reported data. These problems may affect some subgroups more than others.</p>

          {/* <p> *Blood pressure data from 2017.</p> */}
        </>}
      >
        <p style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>{mapArray[selectedTopic].subText}</p>
        <p style={{fontStyle: 'italic', fontSize: '1.25rem', textAlign: 'center'}}>The numbers displayed on the map are the census tract ids.  Click the map to see a popup of data.</p>
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
            {mapArray.map((item) => (
              <StdButton key={item.id} handleClick={() => handleSelectedTopic(item.id)} text={item.title} solid={false} color='#005469' />
            ))}
          </div>
          <div style={{ width: '600px', height: '500px' }} ref={mapRef}></div>
        </div>
      </DataSection>
    </main >
  )
}

export default memo(ExistingHealth)