import React, { useEffect, useRef } from 'react'
import ReportHeader from '../reportHeader/ReportHeader';
import { useDispatch, useSelector } from 'react-redux';
import DataSection from '../dataSection';
import InsetMap from '../insetMap/InsetMap'
import { useNavigate, useParams } from 'react-router-dom';
// import { DataButtons } from '../DataButtons';
import { setNavLoc } from '../../features/navLocSlice'
import handleMissingCounty from '../../tools/handleMissingCounty';
import pdf from './HVI_Methodology_Shope_12_06_2022_V2.pdf'
import "@esri/calcite-components/dist/components/calcite-loader";
// import { CalciteLoader } from '@esri/calcite-components-react';
// import StdButton from '../stdButton/StdButton';
// import handleDownloadPdf from '../../tools/handlePdfDownload';
// import { FaFilePdf } from 'react-icons/fa';
import useLocSync from '../../tools/useLocSync';

const Hvi = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { locType, locId } = useParams()

  useLocSync(locType, locId)

  const pagesRef = {
    page1: useRef(),
    page2: useRef(),
  }

  const county = useSelector(state => state.county)
  const muni = useSelector(state => state.muni)

  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleMissingCounty(county, navigate, dispatch, setNavLoc)
  }, [county, dispatch, navigate])

  return !!county && (
    <main className='page-container'>
      <div ref={pagesRef.page1}>
        <ReportHeader county={county.NAME} muni={!!muni ? muni.MUN_LABEL : null} title='Heat Vulnerability Index' />
      </div>
      {/* <div className='button-container print-hidden'>
        {loading ? <CalciteLoader hidden={loading}></CalciteLoader> : <>
          <p style={{ fontSize: '1.5rem' }}><em>Ensure all maps are fully loaded before downloading information.</em></p>
          <StdButton handleClick={() => {
            setLoading(true)
            handleDownloadPdf(pagesRef).then(resp => setLoading(false))
          }} text={<><FaFilePdf /><span style={{
            margin: '1rem'
          }}>DOWNLOAD PDF</span></>} solid={false} color='#007fac' />

          {
             <StdButton handleClick={handleDownloadData} text={<><FaFileCsv /><span style={{ margin: '1rem' }}>DOWNLOAD DATA</span></>} solid={false} color='#005469' />
          }
        </>
        }
      </div> */}
      <DataSection
        ref={pagesRef.page2}
        description={<>The New Jersey Heat Vulnerability index (HVI) was generated using census data, natural and built environment data, summer temperature records, and community health data. The HVI development was informed by the methodology utilized in New York City, the Greater Boston Area, and the States of Wisconsin, and Vermont. The HVI helps identify the regions within New Jersey that may be most at risk from extreme heat today and bear special consideration in planning for future climate change.  The HVI is provided at the level of census tract. <a style={{ color: 'black' }} rel="noreferrer" href={pdf} target={'_blank'}>View full methodology.</a><br />  <a href='https://rutgers.maps.arcgis.com/apps/mapviewer/index.html?webmap=0a04c1346d4542d081d49c066de0fa9f' target='_blank' rel='noreferrer'>Click here to go to a interactive version of this map.</a></>}
        childHolder='map-holder'
        offspring={<InsetMap portalId={'0a04c1346d4542d081d49c066de0fa9f'} />}
      />

    </main>
  )
}

export default Hvi