// import "@esri/calcite-components/dist/components/calcite-loader";
// import { CalciteLoader } from '@esri/calcite-components-react';
import { useEffect, useRef } from 'react'
import './climateConditions.scss';
import ReportHeader from '../reportHeader/ReportHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setNavLoc } from '../../features/navLocSlice'
import handleMissingCounty from '../../tools/handleMissingCounty';
import HeatWave from './HeatWave';
import LST from './LST';
import Projected from './Projected';
import ExtremeRain from './ExtremeRain';
import FloodZones from './FloodZones';
import SLR from './SLR/SLR';
// import handleDownloadPdf from '../../tools/handlePdfDownload'
// import StdButton from '../stdButton/StdButton';
// import { FaFilePdf } from 'react-icons/fa';
import useLocSync from "../../tools/useLocSync";

const ClimateConditions = () => {

  const printRef = useRef()
  const pagesRef = {
    page1: useRef(),
    page2: useRef(),
    page3: useRef(),
    page4: useRef(),
    page5: useRef(),
    page6: useRef(),
    page7: useRef(),
    page8: useRef(),
    page9: useRef(),
    page10: useRef(),
    page11: useRef(),
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { locType, locId } = useParams()
  useLocSync(locType, locId)

  const county = useSelector(state => state.county)
  const muni = useSelector(state => state.muni)

  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleMissingCounty(county, navigate, dispatch, setNavLoc)
  }, [county, dispatch, navigate])



  return !!county && (

    <main className='page-container' ref={printRef}>
      <div ref={pagesRef.page1} className='ms-header'>
        <ReportHeader title='Climate Conditions that Impact Health' county={county.NAME} muni={!!muni ? muni.MUN_LABEL : null} />
        {/* // ** commented out until we're ready to use these buttons */}
        {/* <DataButtons pageArray={[page1.current, page2.current]} /> */}

      </div>
      {/* <div className='button-container print-hidden'>
        {loading ? <CalciteLoader hidden={loading}></CalciteLoader> : <>
          <p style={{ fontSize: '1.5rem' }}><em>Ensure all maps are fully loaded before downloading information.</em></p>
          <StdButton handleClick={() => {
            setLoading(true)
            handleDownloadPdf(pagesRef).then(resp => setLoading(false))
          }} text={<><FaFilePdf /><span style={{
            margin: '1rem'
          }}>DOWNLOAD PDF</span></>} solid={false} color='#007fac' />

          {
           <StdButton handleClick={handleDownloadData} text={<><FaFileCsv /><span style={{ margin: '1rem' }}>DOWNLOAD DATA</span></>} solid={false} color='#005469' /> 
          }
        </>
        }
      </div> */}
      <LST ref={pagesRef} />
      <HeatWave ref={pagesRef} />
      <Projected ref={pagesRef} />
      <div ref={pagesRef.page6}>
        {!!muni ? <ExtremeRain /> : null}
      </div>
      <FloodZones ref={pagesRef} />
      {muni?.coastal ?? county?.coastal ? <SLR ref={pagesRef} /> : null}

    </main>

  )
}

export default ClimateConditions