import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCountyByGeoID } from "../features/countySlice"
import { getMuniByMunCode, setMuni } from "../features/muniSlice"

//** This is a hook to set the location state based on incoming search params */
const useLocSync = (locType, locId) => {

  // console.log('syncing location', locId, locType)
  
  const dispatch = useDispatch()
  const county = useSelector(state => state.county)
  const muni = useSelector(state => state.muni)
  
  console.log('locType', locType)
  
  useEffect(() => {
    if (locType === 'county') {
      console.log('syncing county', locId, county.id)
      if (locId !== county.id) {
        dispatch(getCountyByGeoID(locId))
        dispatch(setMuni(null))
      }
    } else if (locType === 'muni') {
      if (locId !== muni?.MUN_CODE) {
        dispatch(getMuniByMunCode(locId))
      }
    }
  }, [locType, locId, dispatch, muni, county])
  
  return
}

export default useLocSync