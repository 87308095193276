import { useEffect, useState } from "react";
import MuniDataService from '../../services/muni'

const useMunis = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        let getData = async () => {
            try {
                let response = await MuniDataService.getAllMunis();
                return response
            } catch (error) {
                console.log('useMunis getData error', error)
            }
        }

        getData().then(res => setData(res.data.data))

    }, [])

    return data
}

export default useMunis

