const mapConfig = {
    appName: process.env.REACT_APP_FULL_NAME ?? 'create REACT_APP_FULL_NAME in .env',
    // mapZoom: 8,
    mapScale: 1000000,
    mapCenter: [-74.5, 40.09],
    basemap: 'topo-vector',
    scaleLoc: 'bottom-right',// where to place the scalebar, if '', then will not use
    // if you choose left locations and are using an action bar, the widgets will be hidden unless you include padding in the view options
    zoomLoc: 'top-left', // 'top-right', 'bottom-right', 'top-left', 'bottom-left'
    homeLoc: 'top-left', // where to place the home button, if '', then will not use
    basemapLoc: '',// where to place the scalebar, if '', then will not use
    legendLoc: 'top-left',// where to place the scalebar, if '', then will not use
}

export default mapConfig