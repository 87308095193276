import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setNavLoc } from '../../features/navLocSlice'
import ReportHeader from '../reportHeader/ReportHeader'
import handleMissingCounty from '../../tools/handleMissingCounty';
import GeneralDemo from './GeneralDemo/GeneralDemo';
import RaceDemo from './RaceDemo/RaceDemo';
import HouseDemo from './HouseDemo/HouseDemo';
// import handleDownloadPdf from '../../tools/handlePdfDownload'
// import StdButton from '../stdButton/StdButton';
// import { FaFilePdf } from 'react-icons/fa';
// import "@esri/calcite-components/dist/components/calcite-loader";
// import { CalciteLoader } from '@esri/calcite-components-react';
import useLocSync from '../../tools/useLocSync';


const SocioDemo = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { locType, locId } = useParams()

  const pagesRef = {
    page1: useRef(),
    page2: useRef(),
    page3: useRef(),
    page4: useRef(),
    page5: useRef(),
    page6: useRef(),
    page7: useRef(),
    page8: useRef(),
    page9: useRef(),
  }

  const county = useSelector(state => state.county)
  const muni = useSelector(state => state.muni)

  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleMissingCounty(county, navigate, dispatch, setNavLoc)
  }, [county, dispatch, navigate])

  useLocSync(locType, locId)

  return !!county && (
    <main className='page-container'>
      <div ref={pagesRef.page1}>
        <ReportHeader county={county.NAME}
          muni={!!muni ? muni.MUN_LABEL : null}
          title='Socio-demographic Factors at Greater Risk of Negative Health Outcomes from Climate Change' />
        <p style={{ fontSize: '1.6rem' }}>While the health of all people living in the United States is affected by climate change, some communities and some populations are more vulnerable to changing climate conditions than others. Populations of concern include those with low income, communities of color, immigrant groups, Indigenous peoples, children and pregnant women, older adults, outdoor laborers, persons with disabilities, and persons with preexisting or chronic medical conditions. Factors such as poverty, lack of access to transportation, limited English proficiency, and crowded or substandard housing may weaken an individual's ability to adapt to a changing climate. Existing societal inequities also impede the ability of an individual or community to respond to climate change. These inequities may include unequal access to social, community based, and economic opportunities that enhance health and well-being, disproportionate exposure to environmental hazards, and social isolation.</p>
      </div>
      {/* <div className='button-container print-hidden'>
        {loading ? <CalciteLoader hidden={loading}></CalciteLoader> : <>
          <p style={{ fontSize: '1.5rem' }}><em>Ensure all maps are fully loaded before downloading information.</em></p>
          <StdButton handleClick={() => {
            setLoading(true)
            handleDownloadPdf(pagesRef).then(resp => setLoading(false))
          }} text={<><FaFilePdf /><span style={{
            margin: '1rem'
          }}>DOWNLOAD PDF</span></>} solid={false} color='#007fac' />

          {
             <StdButton handleClick={handleDownloadData} text={<><FaFileCsv /><span style={{ margin: '1rem' }}>DOWNLOAD DATA</span></>} solid={false} color='#005469' /> 
          }
        </>
        }
      </div> */}
      <div ref={pagesRef.page2}>
      </div>
      <div ref={pagesRef.page3}>
        <GeneralDemo />
      </div>
      <div ref={pagesRef.page4}>
        <RaceDemo />
      </div>
      <div ref={pagesRef.page5}>
        <HouseDemo />
      </div>

    </main>
  )
}

export default SocioDemo