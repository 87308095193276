// inspired by https://www.positronx.io/react-file-upload-tutorial-with-node-express-and-multer/

import http from "../http-common";

class EphDataService {

    getEph(summaryType, locationId) {
        // console.log('summary', summaryType, locationId)
        return http.get(`/eph/${summaryType}/${locationId}`)
    }
}

export default new EphDataService();
