// inspired by https://www.positronx.io/react-file-upload-tutorial-with-node-express-and-multer/

import http from "../http-common";

class CountyDataService {

    getAllCounties() {
        return http.get(`/county`)
    }
    updateCounty(id, data) {
        return http.put(`/county/${id}`, data)
    }
    getOneCounty(id) {
        return http.get(`/county/${id}`)
    }
}

export default new CountyDataService();
