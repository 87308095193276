// inspired by https://www.positronx.io/react-file-upload-tutorial-with-node-express-and-multer/

import http from "../http-common";

class CensusDataService {

    getCensus(summaryType, locationId, year) {
        // console.log('summary', summaryType, locationId)
        return http.get(`/census/raw/${summaryType}/${locationId}/${year}`)
    }
}

export default new CensusDataService();
