import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from './store.js';
import App from './App';
import './index.scss';
import { persistStore } from 'redux-persist';
import { PersistGate } from  'redux-persist/lib/integration/react';


import { setAssetPath } from '@esri/calcite-components/dist/components'
setAssetPath("https://js.arcgis.com/calcite-components/1.0.0-beta.97/assets");

const root = createRoot(document.getElementById('root'));

const store = configureStore();
const persistor = persistStore(store);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
        </PersistGate>
    </Provider>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();



      