const LocDropDown = ({ label, handleChange, defaultText, optionArray, unique, valueField, nameField, currVal, disabled }) => {

    return (
        <label>{label}
            <select onChange={handleChange} value={currVal} disabled={disabled}>
                <option value={null}>{defaultText}</option>
                {optionArray.map(item => (<option key={item[unique]} value={item[valueField]} >{item[nameField]}</option>)
                )}
            </select>
        </label>
    )
}

export default LocDropDown