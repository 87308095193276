import React, { useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import HC_exporting from 'highcharts/modules/exporting'
import HC_export_data from 'highcharts/modules/export-data'
import highchartsAccessibility from "highcharts/modules/accessibility";

HC_exporting(Highcharts)
highchartsAccessibility(Highcharts)
HC_export_data(Highcharts)
highchartsMore(Highcharts);



const Chart = ({ chartOptions }) => {
    const chartRef = useRef(null);

    Highcharts.setOptions({
        lang: {
            thousandsSep: ","
        }
    });

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                ref={chartRef}
            />
        </div>
    );
};

export default Chart;
