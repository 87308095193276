import DataSection from "../../dataSection"
import DataTable from '../../dataTable'
import Chart from '../../Chart'
import useAgolData from "./tools/useAgolData"
import { useSelector } from 'react-redux'


const HealthSites = () => {

    const muni = useSelector(state => state.muni)

    // ** filter the data for the location of interest
    let filteredEps = useAgolData(!!muni ? '54dfcdd168ee4daa885515ccba312d43' : 'bb8c1f5e201547d1a4ad5b69b148904a', 'Toxic Catastrophe Prevention Act (TCPA) Sites')
    let filteredKcs = useAgolData(!!muni ? '3c208d16783d4e46993d241151c8772f' : '0ce5954331914e0292bc09bec1f2e4dd', 'Known Contaminant Sites')
    let filteredScrap = useAgolData(!!muni ? 'df1971fca9a04072b78f88b8969e2d38' : '5b327f1da46240f3906b5db068926f89', 'Scrap Metal Facilities')
    let filteredWaste = useAgolData(!!muni ? '1bab8579dfda4526b43a3a2601861830' : '6ba35d9fb8e6447d856cc475fccd4702', 'Solid Waste Facilities')
    let filteredSoil = useAgolData(!!muni ? '8d5d0bb70634480aa0d78197c49064d4' : 'f64ed0c7de3346d8b790a83e87a455c0', 'Soil Contamination Deeds')
    let filteredWells = useAgolData(!!muni ? '884fdb0267244a65acea59ab54df92bd' : '538cff514c504173a1cd13f3e03288f1', 'Restricted Wells')

    // console.log('filteredEps', filteredKcs)

    let caption = ``
    // let caption = `*The sites listed as 0.2% chance of flooding each year are in addition to the 1% chance sites.`
    let tableData = [filteredEps, filteredKcs, filteredScrap, filteredWaste,
        filteredSoil, filteredWells]

    // ** define table header **
    let columns = [
        '',
        'Total number of sites',
        '2ft sea level rise',
        '5ft sea level rise',
        '7ft sea level rise',
        'FEMA 1% chance flood',
        'FEMA 0.2% chance flood',
    ]

    //** tableData has 6, so need 6 colors, pulling from Rutgers colors */
    // let RUGrey = '#5f6a72'
    let RUBlue = '#007fac'
    let RUTeal = '#00626d'
    let RUGreen = '#9ea900'
    // let RUYellow = '#ebb600'
    let RUOrange = '#e76f00'
    let RURed = '#cc0033'
    // let RUBeige = '#dfd2b3'
    let RUBrown = '#703221'
    // let RUGreyBeige = '#c1bbab'
    let colors = [RURed, RUBrown, RUBlue, RUGreen, RUOrange, RUTeal]
    let chartData = tableData.map((item, i) => ({ name: item[0], data: item.slice(1), color: colors[i] }))

    let chartOptions = {
        margin: 0,
        chart: {
            type: 'column'
        },
        title: {
            text: `Number of sites exposed to potential flooding`,
        },
        subtitle: {
            text: `Click the name in the legend to turn off a variable and focus on another.`
        },
        xAxis: {
            categories: ['Total number of sites',
                '2ft sea level rise',
                '5ft sea level rise',
                '7ft sea level rise',
                'FEMA 1% chance flood',
                'FEMA 0.2% chance flood'],
        },
        yAxis: {
            title: {
                text: 'number of sites'
            },
            labels: {
                format: '{value:,.0f}'
            },
        },
        // tooltip: {
        //     crosshairs: true,
        //     shared: true,
        // },
        series: chartData,
    }

    // console.log(chartOptions)
    return (
        <DataSection
            title={'Sites in Potentially Flooded Areas that Could Impact Health'}
            description={<p>Some commercial or industrial facilities and polluted sites are at a greater risk of allowing new or expanded exposure of hazardous substances if impacted by flood hazards.  These hazardous substances may contaminate soil, waterways, or drinking water supplies and have significant impact on populations living in those areas, many of which are often socially vulnerable.  The likelihood and frequency of such flood hazard events is expected to increase in the future due to climate change and increase the risks these locations may have on public health. Read more about contaiminated sites in New Jersey <a href='https://dep.nj.gov/wp-content/uploads/ej/docs/njdep-ej-technical-guide.pdf#page=32' target={'_blank'} rel="noreferrer" >here</a>.</p>}
            childHolder='table-holder'
            childLabel={'Table 2'}
        >
            <Chart chartOptions={chartOptions} />
            <DataTable header={columns} data={tableData}
                topCaption={caption}
            />
        </DataSection>
    )
}
export default HealthSites