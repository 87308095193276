// inspired by https://www.positronx.io/react-file-upload-tutorial-with-node-express-and-multer/

import http from "../http-common";

class RainDataService {

    getRain(munCode) {
        return http.get(`/rain/${munCode}`)
    }

    createRain(data) {
        return http.post(`/rain`, data)
    }
}

export default new RainDataService();
