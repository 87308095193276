import Chart from "../../Chart"
import DataSection from "../../dataSection/DataSection"
import countyDaysData from './pm2Data/countyPm2Days.json'
import muniDaysData from './pm2Data/muniPm2Days.json'
import countyConcData from './pm2Data/countyPm2Conc.json'
import { useSelector } from "react-redux"

const PM25 = () => {

    const county = useSelector(state => state.county)
    const muni = useSelector(state => state.muni)

    let filteredDaysData
    if (!!muni) {
        filteredDaysData = muniDaysData.find(item => parseInt(item.MUN_CODE) === parseInt(muni.MUN_CODE))
    } else {
        filteredDaysData = countyDaysData.find(item => parseInt(item.CountyFIPS) === parseInt(county.GEOID))
    }

    let filteredDataConc = countyConcData.find(item => parseInt(item.CountyFIPS) === parseInt(county.GEOID))

    let daysCategories = Object.keys(filteredDaysData).filter(item => item.includes('ThreeYr')).map(item => item.split("Avg")[1])
    let daysData = Object.entries(filteredDaysData).filter(item => item[0].includes('ThreeYr')).map(item => item[1])

    // console.log('pm2 days chart test', categories)

    let daysChartOptions = {
        margin: 0,
        title: {
            text: `Days over PM2.5 24-hour standard of 35 µg/m3`,
        },

        xAxis: {
            categories: daysCategories,
        },
        yAxis: {
            title: {
                text: 'days'
            },

        },
        tooltip: {
            crosshairs: true,
            shared: true,
        },
        series: [
            {
                name: "PM2.5 Days",
                type: "line",
                data: daysData,
                color: '#007fac'
            },
        ],
    }

    let concCategories = Object.keys(filteredDataConc).filter(item => item.includes('ThreeYr')).map(item => item.split("Con")[1])
    let concData = Object.entries(filteredDataConc).filter(item => item[0].includes('ThreeYr')).map(item => item[1])

    let concChartOptions = {
        margin: 0,
        title: {
            text: `Annual mean PM2.5 concentration`,
        },

        xAxis: {
            categories: concCategories,
        },
        yAxis: {
            title: {
                text: 'µg/m3'
            },

        },
        tooltip: {
            crosshairs: true,
            shared: true,
        },
        series: [
            {
                name: "PM2.5 concentration",
                type: "line",
                data: concData,
                color: '#007fac'
            },
        ],
    }

    return (
        <>
            <DataSection title='Particulate Matter 2.5'
                description={<>
                    <p>
                        Fine particulate matter 2.5 describes the tiny particles which create larger bodies of pollution. PM2.5 particles are small enough to travel deeply into the body and create negative health outcomes as they accumulate in the lungs. Studies have linked PM2.5 exposure to increases in respiratory and cardiovascular issues. PM2.5 can be carried on the wind far distances from their original source, usually originating from processes like fuel burning, including the fuel-burning which takes place during vehicle based travel.</p>
                    <p>Information on PM2.5 is presented as a rolling 3-year average of days per year over the air quality standard. Data on days over the ozone air quality standard is provided by the US Environmental Protection Agency through their <a style={{ color: 'black' }} href='https://www.epa.gov/AirToxScreen' target={'_blank'} rel="noreferrer">AirToxScreen portal</a> and the <a href='https://ephtracking.cdc.gov/DataExplorer/' target="_blank" rel="noreferrer" >CDC Public Health Data Explorer</a>.   Read more about PM2.5 in New Jersey <a href='https://dep.nj.gov/wp-content/uploads/ej/docs/njdep-ej-technical-guide.pdf#page=17' target={'_blank'} rel="noreferrer" >here</a>.</p></>}
                childHolder='table-holder'
                //  childLabel={'Figure 3'}
            >
                <Chart chartOptions={daysChartOptions} />
            </DataSection>
            <DataSection
                description={'Annual mean concentration is available for the county level only.'}
                childHolder='table-holder'
                //  childLabel={'Figure 4'}
            >
                <Chart chartOptions={concChartOptions} />
            </DataSection>
        </>
    )
}
export default PM25