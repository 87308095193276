const mapArray = [
    { id: 0, topic: 'asthma', title: 'Asthma', portalId: '4989a0a9c7db499b9198240d2de613a9', subText: 'Crude Prevalence of Current Asthma among Adults (Model Based)' },
    { id: 1, topic: 'copd', title: 'COPD', portalId: 'acea6abdd837404b9ec0162c648efec0', subText: 'Crude Prevalence of Chronic Obstructive Pulmonary Disease (COPD) among Adults (Model Based)' },
    { id: 2, topic: 'coronary', title: 'Coronary Heart Disease', portalId: '99082186087344968108e32709cda5e7', subText: 'Crude Prevalence of Coronary Heart Disease among Adults (Model Based)' },
    { id: 3, topic: 'drinking', title: 'Heavy Drinking', portalId: '5b12183204f043e3a6ebbba7f0bf1f26', subText: 'Crude Prevalence of Binge Drinking among Adults (Model Based)' },
    { id: 4, topic: 'obesity', title: 'Obesity', portalId: '321ee46d269242e6b6c2a5aeb38e2aad', subText: 'Crude Prevalence of Obesity among Adults (Model Based)' },
    { id: 5, topic: 'smoking', title: 'Smoking', portalId: '4ea6dcd3f5a6474686f82f47843c8b4c', subText: 'Crude Prevalence of Current Smoking among Adults (Model Based)' },
    { id: 6, topic: 'diabetes', title: 'Diabetes', portalId: 'b3b28d0499d1489fbba77a63b7e03710', subText: 'Crude Prevalence of Diabetes among Adults (Model Based)' },
    { id: 7, topic: 'bloodPressure', title: 'High Blood Pressure', portalId: 'be2b05fa377f45c593bb5d4267d76f9d', subText: 'Crude Prevalence of High Blood Pressure among Adults (Model Based)' }]

    export default mapArray;