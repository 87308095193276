import {
  createStore,
  combineReducers,
  applyMiddleware, //for redux thunk
} from "redux";

import { county } from './features/countySlice'
import { muni } from './features/muniSlice'
import { viewOpts } from "./features/viewOptsSlice";
import { navLoc } from "./features/navLocSlice";

//next three for persisting
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

//redux thunk specifically
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const reducers = {
  county,
  muni,
  navLoc,
  viewOpts,
};

const persistConfig = {
  key: "calcite-map-redux",
  storage,
  stateReconciler: autoMergeLevel2,
};
const rootReducer = combineReducers(reducers);

//persisting
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureStore = () => {
  return createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk)),
  );
};
