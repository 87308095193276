import { muniLayer } from "../../../data/layers/boundaries/muniLayer"
import { countyLayer } from "../../../data/layers/boundaries/countyLayer"
import {
    gt95Hist,
    gt95rcp4530,
    gt95rcp4560,
    gt95rcp4590,
    gt95rcp8530,
    gt95rcp8560,
    gt95rcp8590,
    cddHist,
    cddrcp4530,
    cddrcp4560,
    cddrcp4590,
    cddrcp8530,
    cddrcp8560,
    cddrcp8590,
    maxtHist,
    maxtrcp4530,
    maxtrcp4560,
    maxtrcp4590,
    maxtrcp8530,
    maxtrcp8560,
    maxtrcp8590,
} from './layers'
import ClimateDataService from '../../../services/climate'

const concatProjClim = async (locCode, locId, isMuni) => {

    //** if is county, locCode is FIPSSTCO, locId is countyId */

    try {
        let data, output
        let fieldArray = [
            gt95Hist,
            gt95rcp4530,
            gt95rcp4560,
            gt95rcp4590,
            gt95rcp8530,
            gt95rcp8560,
            gt95rcp8590,
            cddHist,
            cddrcp4530,
            cddrcp4560,
            cddrcp4590,
            cddrcp8530,
            cddrcp8560,
            cddrcp8590,
            maxtHist,
            maxtrcp4530,
            maxtrcp4560,
            maxtrcp4590,
            maxtrcp8530,
            maxtrcp8560,
            maxtrcp8590,
        ]

        let dataJson = {
            gt95Hist: 0,
            gt95rcp4530: 0,
            gt95rcp4560: 0,
            gt95rcp4590: 0,
            gt95rcp8530: 0,
            gt95rcp8560: 0,
            gt95rcp8590: 0,
            cddHist: 0,
            cddrcp4530: 0,
            cddrcp4560: 0,
            cddrcp4590: 0,
            cddrcp8530: 0,
            cddrcp8560: 0,
            cddrcp8590: 0,
            maxtHist: 0,
            maxtrcp4530: 0,
            maxtrcp4560: 0,
            maxtrcp4590: 0,
            maxtrcp8530: 0,
            maxtrcp8560: 0,
            maxtrcp8590: 0,
        }

        const queryFeatureLayer = async (geometry, layer) => {
            try {
                let query = {
                    spatialRelationship: 'intersects',
                    geometry: geometry,
                    outfields: ['*'],
                    returnGeometry: true
                }
                let results = await layer.queryFeatures(query)
                return results
            } catch (err) {
                console.log('queryFeatureLayer.error', err)
            }

        }
        const iterateLayers = async (locationResults, layerArray, outputObj) => {
            for await (let layer of layerArray) {
                // ** muniResults.features[0] should be the one municipality that matches our MUN_CODE, we want to pass the geometry to the query function
                let tempResults = await queryFeatureLayer(locationResults.features[0].geometry, layer)
                outputObj[layer.oraId] = tempResults.features.map(item => Object.values(item.attributes))

            }
            return outputObj
        }
        if (isMuni) {
            // console.log('process muni')
            let muniResults = await muniLayer.queryFeatures({ where: `MUN_CODE = '${locCode}'`, outfields: ['*'], returnGeometry: true })

            data = await iterateLayers(muniResults, fieldArray, dataJson).then(results => results)
        } else {
            // console.log('process county climate')
            let countyResults = await countyLayer.queryFeatures({ where: `FIPSSTCO = '${locCode}'`, outfields: ['*'], returnGeometry: true })
            data = await iterateLayers(countyResults, fieldArray, dataJson).then(results => results)
        }
        //** July is the 6th month in the array that start with Jan as 0 */
        output = [
            { locationId: locId, summaryType: isMuni ? 'muni' : 'county', year: 2010, month: 6, scenario: 'historical', cdd_max: data.cddHist[data.cddHist.length - 1][0], cdd_min: data.cddHist[0][0], maxt_max: data.maxtHist[data.maxtHist.length - 1][0], maxt_min: data.maxtHist[0][0], gt95_max: data.gt95Hist[data.gt95Hist.length - 1][0], gt95_min: data.gt95Hist[0][0] },
            { locationId: locId, summaryType: isMuni ? 'muni' : 'county', year: 2030, month: 6, scenario: 'rcp45', cdd_max: data.cddrcp4530[data.cddrcp4530.length - 1][0], cdd_min: data.cddrcp4530[0][0], maxt_max: data.maxtrcp4530[data.maxtrcp4530.length - 1][0], maxt_min: data.maxtrcp4530[0][0], gt95_max: data.gt95rcp4530[data.gt95rcp4530.length - 1][0], gt95_min: data.gt95rcp4530[0][0] },
            { locationId: locId, summaryType: isMuni ? 'muni' : 'county', year: 2060, month: 6, scenario: 'rcp45', cdd_max: data.cddrcp4560[data.cddrcp4560.length - 1][0], cdd_min: data.cddrcp4560[0][0], maxt_max: data.maxtrcp4560[data.maxtrcp4560.length - 1][0], maxt_min: data.maxtrcp4560[0][0], gt95_max: data.gt95rcp4560[data.gt95rcp4560.length - 1][0], gt95_min: data.gt95rcp4560[0][0] },
            { locationId: locId, summaryType: isMuni ? 'muni' : 'county', year: 2090, month: 6, scenario: 'rcp45', cdd_max: data.cddrcp4590[data.cddrcp4590.length - 1][0], cdd_min: data.cddrcp4590[0][0], maxt_max: data.maxtrcp4590[data.maxtrcp4590.length - 1][0], maxt_min: data.maxtrcp4590[0][0], gt95_max: data.gt95rcp4590[data.gt95rcp4590.length - 1][0], gt95_min: data.gt95rcp4590[0][0] },
            { locationId: locId, summaryType: isMuni ? 'muni' : 'county', year: 2030, month: 6, scenario: 'rcp85', cdd_max: data.cddrcp8530[data.cddrcp8530.length - 1][0], cdd_min: data.cddrcp8530[0][0], maxt_max: data.maxtrcp8530[data.maxtrcp8530.length - 1][0], maxt_min: data.maxtrcp8530[0][0], gt95_max: data.gt95rcp8530[data.gt95rcp8530.length - 1][0], gt95_min: data.gt95rcp8530[0][0] },
            { locationId: locId, summaryType: isMuni ? 'muni' : 'county', year: 2060, month: 6, scenario: 'rcp85', cdd_max: data.cddrcp8560[data.cddrcp8560.length - 1][0], cdd_min: data.cddrcp8560[0][0], maxt_max: data.maxtrcp8560[data.maxtrcp8560.length - 1][0], maxt_min: data.maxtrcp8560[0][0], gt95_max: data.gt95rcp8560[data.gt95rcp8560.length - 1][0], gt95_min: data.gt95rcp8560[0][0] },
            { locationId: locId, summaryType: isMuni ? 'muni' : 'county', year: 2090, month: 6, scenario: 'rcp85', cdd_max: data.cddrcp8590[data.cddrcp8590.length - 1][0], cdd_min: data.cddrcp8590[0][0], maxt_max: data.maxtrcp8590[data.maxtrcp8590.length - 1][0], maxt_min: data.maxtrcp8590[0][0], gt95_max: data.gt95rcp8590[data.gt95rcp8590.length - 1][0], gt95_min: data.gt95rcp8590[0][0] },
        ]
        // let freshData =
        await ClimateDataService.createClimate(output)
        // console.log('result1', freshData)
        // console.log('result2', output)
        return output

    } catch (err) {
        console.log('error', err)
        alert('There was an issue getting the projected climate data: concatProjClim.err', err)
    }

}

export default concatProjClim