import "@esri/calcite-components/dist/components/calcite-loader";
import {FaFileAlt} from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setNavLoc } from '../../features/navLocSlice'
import './reportNotice.scss'
import { CalciteLoader } from '@esri/calcite-components-react'

const ReportNotice = ({ title, path, loading }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(setNavLoc(path)) 
        navigate(`/${path}`)   
    }
    
    return (
        <div
            className='report-notice'
            onClick={handleClick}
        ><div style={{display: 'flex', alignItems: 'center', width: '50px', height: '50px'}}>
            {loading ? <CalciteLoader></CalciteLoader> : 
            <FaFileAlt color='#2f85ae' size='4.8rem' title='file icon' />}
        </div>
            <span>{title}</span>
        </div>
    )
}

export default ReportNotice