import { useSelector } from "react-redux"
import DataSection from "../../dataSection/DataSection"
import muniData from './trafficData/muniTraffic.json'
import countyData from './trafficData/countyTraffic.json'
import DataTable from "../../dataTable/DataTable"

const Traffic = () => {

    // ** get selected location
    const county = useSelector(state => state.county)
    const muni = useSelector(state => state.muni)

    // ** filter the data for the county of interest
    let filteredCounty = Number(countyData.find(item => parseInt(item.GEOID) === parseInt(county.GEOID)).Avg_Light_Comb_TruckAADT.toFixed(0)).toLocaleString()
    let filteredMuni = Number(muniData.find(item => parseInt(item.MUN_CODE) === parseInt(muni?.MUN_CODE))?.Avg_Light_Comb_TruckAADT.toFixed(0)).toLocaleString()


    // ** sort the data by trucks, reverse the order so the most is #1, get the index of current county and add 1 (because 0 is the first index)
    let calculatedRank = countyData.sort((a, b) => a.Avg_Light_Comb_TruckAADT - b.Avg_Light_Comb_TruckAADT).reverse().map(item => item.GEOID).findIndex(item => parseInt(item) === parseInt(county.GEOID)) + 1

    let tableData = !!muni ? [filteredMuni, filteredCounty] : [filteredCounty, calculatedRank]
    let tableColumns = !!muni ? ['Municipal Data', 'County Data'] : ['County Data', "County Rank in State"]
    let caption = !!muni ? <span style={{ fontWeight: 'bold' }}>Annual Daily Average Combined Number of Cars, Light Trucks and Heavy Trucks</span> : <><span style={{ fontWeight: 'bold' }}>Annual Daily Average Combined Number of Cars, Light Trucks and Heavy Trucks</span><br></br> The ranking refers to this county's rank in the state, with 1 having the most traffic and 21 having the least.</>

    return (
        <DataSection
            title='Average Annual Daily Traffic Data'
            description={<>
                Average Annual Daily Traffic (AADT) describes the typical number of vehicles traveling on a given roadway over the course of a day. Average AADT per year are presented in Table 1. Greater AADT values within a community are linked to greater exposure to tailpipe emissions and worse air quality (especially when combined with higher temperatures), which can exacerbate negative health outcomes such as hospitalizations for asthma or other respiratory illnesses. AADT data come from the <a style={{ color: 'black' }} href='https://experience.arcgis.com/experience/548632a2351b41b8a0443cfc3a9f4ef6' target={'_blank'} rel="noreferrer">New Jersey DEP Environmental Justice Mapping Tool</a> and the <a style={{ color: 'black' }} href='https://geo.dot.gov/server/rest/services/Hosted/NewJersey_2018_PR/FeatureServer' target={'_blank'} rel="noreferrer">2018 New Jersey Traffic Data GIS</a>.</>}
            childHolder='table-holder'
            childLabel={'Table 1'}
        >
            <DataTable data={tableData} header={tableColumns} topCaption={caption} />
            <div style={{ fontSize: '1.6rem' }}>
                <p> Average Annual Daily Traffic (AADT) is a year spanning average for daily traffic on a given road segment. Guidance in methodology for calculating average AADT by geography can be found at: <a href='https://dep.nj.gov/wp-content/uploads/ej/docs/njdep-ej-technical-guide.pdf#page=26' target="_blank" rel='noreferrer'>New Jersey Guidance Document for Environmental Justice</a>. For the purpose of this dataset, "Truck" qualifies as any light or combination vehicle, classifications 4 - 13 of the FHWA guidelines. More information on vehicle classification can be found at: <a href="https://www.fhwa.dot.gov/policyinformation/tmguide/tmg_2013/vehicle-types.cfm" target="_blank" rel='noreferrer' >FHA Traffic Monitoring Guide</a>.</p>
            </div>
        </DataSection>
    )
}
export default Traffic