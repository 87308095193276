import rainData from '../components/climateConditions/ExtremeRain/rainData.json'
import RainDataService from '../services/rain'

const getRainData = async (county, muni) => {
    console.log('getting rain data')
    if (!county && !muni) {
        return
    }
    try {

        //** get rain data from db */
        let response = await RainDataService.getRain(muni.MUN_CODE)
        let dbRainData = response.data.data
        // console.log('db data received', dbRainData) // {MUN_CODE, data}

        if (!dbRainData || !dbRainData.data) {
            //** if no rain data in db, create it */
            // console.log('no rain data in db, pulling from json')
            let jsonRainData = rainData.data.find(item => item.town_name.toLowerCase() === muni.MUN_LABEL.toLowerCase() && item.county.toLowerCase() === county.NAME.toLowerCase())
            // console.log('found json rain data', jsonRainData)
            let response = await RainDataService.createRain({ MUN_CODE: muni.MUN_CODE, data: jsonRainData })
            if (response.status !== 200) {
                throw new Error('error creating rain data')
            }
            // console.log('created rain data in db', response.data.data)
            dbRainData = response.data.data
        }

        // console.log('db data', dbRainData)
        // should return an object like what is stored in db {MUN_CODE, data}
        return dbRainData

    } catch (err) {
        console.log(err)
    }


}

export default getRainData