import { useSelector } from 'react-redux'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import Query from "@arcgis/core/rest/support/Query.js";
import { useState, useEffect, useMemo } from 'react';

const useAgolData = (portalId, title) => {
    // console.log('useAgolData', portalId, title)
    const county = useSelector(state => state.county)
    const muni = useSelector(state => state.muni)

    const [output, setOutput] = useState([])

    const portalItem = useMemo(() => new FeatureLayer({
        portalItem: {
            id: portalId
        }
    }), [portalId])

    const query = useMemo(() => new Query(), [])
    query.outFields = ['*']

    if (!!muni) {
        // console.log('muni', muni)
        query.where = `MUN_CODE = '${muni.MUN_CODE}'`


    } else {
        query.where = `FIPSSTCO = '${county.GEOID}'`

    }

    useEffect(() => {

        portalItem.load().then(() => {
            portalItem.queryFeatures(query).then((results) => {
                let { totalSites, SitesAffected2ftRise, SitesAffected5ftRise, SitesAffected7ftRise, SitesWithin100yr, SitesWithin500yr } = results.features[0].attributes

                setOutput([title, totalSites, SitesAffected2ftRise, SitesAffected5ftRise, SitesAffected7ftRise, SitesWithin100yr, SitesWithin500yr])
            }).catch(err => {
                console.log('useAgolData Error', err)
            })
        }
        )
    }, [county, muni, portalItem, query, title])


    return output
}

export default useAgolData