import { useSelector } from "react-redux"
import DataSection from "../../dataSection/DataSection"
import DataTable from "../../dataTable"
import Chart from "../../Chart/Chart"
import calculateCensusData from "./calculateCensusData"

const GeneralDemo = () => {

  const muni = useSelector(state => state.muni)
  const county = useSelector(state => state.county)

  let censusData = !!muni ? muni.censusData : county.censusData

  console.log('census data year', censusData.year)

  let demoData = calculateCensusData(censusData)
  let tableHeader = ['', 'Population Estimate', 'Population Prevalence']
  let tableData = Object.values(demoData)
  let caption = <span>Total population: {Number(censusData.B01001_001E).toLocaleString()}</span>

  let categories = tableData?.map(item => Object.values(item)[0])
  let chartData = tableData?.map(item => Number(Object.values(item)[2].slice(0, Object.values(item)[2].length - 1)))
  // console.log('censusData', chartData)
  let chartOptions = {
    chart: {

      type: 'column',
      width: 800,
      // margin: 0,
    },
    title: {
      text: ``,
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: 'percent'
      },

    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    series: [

      {
        name: "% of   population",
        type: "column",
        data: chartData,
        color: '#007fac'
      },
    ],
  }
  return (
    <DataSection title={`General Proportional Demographics ${process.env.REACT_APP_CENSUS_YEAR}`}>
      <Chart chartOptions={chartOptions} />
      <DataTable data={tableData} header={tableHeader} topCaption={caption} />
    </DataSection>
  )
}
export default GeneralDemo