import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

  export const countyLayer = new FeatureLayer({
    url: "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/Political/NJ_Political_Boundaries/MapServer/0",
    // definitionExpression: `FIPSSTCO = '${county.GEOID}'`,
    renderer: {
      type: 'simple',
      symbol: {
        type: "simple-line",
        color: 'black',
        width: '2px',
      }
    }
  })
