import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const url = "https://njmaps1.rad.rutgers.edu/arcgis/rest/services/Political/NJ_Political_Boundaries/MapServer/1";

// const portalId = "a4dde5be06b04ad687bb9fe244ecdffe";

export const muniLayer = new FeatureLayer({
  url,
  renderer: {
    type: 'simple',
    symbol: {
      type: "simple-line",
      color: 'black',
      width: '1px',
    }
  }
});
