import { getCountyCenter } from './viewOptsSlice';
import concatProjClim from '../components/climateConditions/Projected/concatProjClim';
import CountyDataService from '../services/county'
import CensusDataService from '../services/census'
import EphDataService from '../services/eph'

// ** action
export const SET_COUNTY = "SET_COUNTY";
export const setCounty = data => ({
    type: SET_COUNTY,
    payload: { data }
});

export const APPEND_COUNTY = 'APPEND_COUNTY';
export const appendCounty = data => ({
    type: APPEND_COUNTY,
    payload: { data }
})

// ** thunks
export const selectCounty = basicCounty => async (dispatch, getState) => {
    console.log('select county', basicCounty)
    // // ** set up all of the data ahead of time 
    if (basicCounty === null) {
        dispatch(setCounty(null))
    } else {
        // ** set basic county *
        dispatch(setCounty(basicCounty))

        // ** get county center *


        dispatch(getCountyCenter(basicCounty))

        if (basicCounty.coastal === null) {
            let coast = determineCoast(basicCounty)
            let result = await CountyDataService.updateCounty(basicCounty.id, coast)
            console.log('selectCounty', result.data.data)
            dispatch(appendCounty(coast))
        }
        //** check for other data sets */
        let oneCounty = await CountyDataService.getOneCounty(basicCounty.id)
        let fullCounty = oneCounty.data.data
        dispatch(setCounty(fullCounty))
        let censusYear = process.env.REACT_APP_CENSUS_YEAR

        //** if there isn't climate data or if some is missing, go get it should be length 7 */
        if (!fullCounty.climateData || !fullCounty.climateData.length) {
            //** concatProjClim is updating the database with the data */
            let projClim = await concatProjClim(basicCounty.GEOID, basicCounty.id, false);
            dispatch(appendCounty({ climateData: projClim }))
        } else {
            console.log('climate data exists', fullCounty.climateData)
        }
        if (!fullCounty.censusData || fullCounty.censusData.year.toString() !== censusYear.toString()) {
            let censusData = await CensusDataService.getCensus('county', basicCounty.id, censusYear);
            // console.log('censusData', censusData.data)
            dispatch(appendCounty({ censusData: censusData.data }))
        } else {
            console.log('census data exists', fullCounty.censusData)
        }
        if (!fullCounty.ephData) {
            let ephData = await EphDataService.getEph('county', basicCounty.id)
            dispatch(appendCounty({ ephData: ephData.data.data }))
        } else {
            console.log(`ephData exists`, fullCounty.ephData)
        }

    }
}

export const getCounty = countyId => async (dispatch, getState) => {
    try {
        let oneCounty = await CountyDataService.getOneCounty(countyId)
        let fullCounty = oneCounty.data.data
        dispatch(selectCounty(fullCounty))
    } catch (err) { console.log('error getting county by id', err) }
}

export const getCountyByGeoID = geoId => async (dispatch, getState) => {
    try {
        let response = await CountyDataService.getAllCounties()
        let basicCounty = response.data.data.find(county => county.GEOID === geoId)
        dispatch(getCounty(basicCounty.id))
    } catch (err) { console.log('error getting county by id', err) }
}

// ** reducer
export const county = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_COUNTY: {
            let { data } = payload
            console.log('set county slice', data)
            return data
        }
        case APPEND_COUNTY: {
            let { data } = payload
            let freshCounty = { ...state, ...data }
            console.log('appended county', freshCounty)
            return freshCounty
        }
        default: {
            return state;
        }
    }
}

//** extra functions */
const determineCoast = (county) => {
    console.log('getting coastal, county')
    const notCoast = ['sussex', 'warren', 'hunterdon', 'somerset', 'passaic', 'morris']
    console.log('appending coastal', county)
    let result
    if (notCoast.includes(county.NAME.toLowerCase())) {
        console.log('this county is not coastal',)
        result = { coastal: 0 }

    } else {
        console.log('this county is coastal')
        result = { coastal: 1 }
    }
    console.log('coast', result)
    return result
}
