import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap.js";
import Home from "@arcgis/core/widgets/Home";
import mapConfig from "../data/mapConfig";
import Legend from "@arcgis/core/widgets/Legend";
import Expand from "@arcgis/core/widgets/Expand";

const createMapView = (mapProps, viewProps) => {
// console.log('creating map view')
    try {

        let fullMapProps = {
            ...mapProps
        }

        let fullViewProps = {
            ...viewProps
        }

        const theMap = new WebMap(fullMapProps)

        const view = new MapView({
            map: theMap,
            popup: {
                defaultPopupTemplateEnabled: true,
                dockEnabled: true,
                dockOptions: {
                    //     // Disables the dock button from the popup
                    buttonEnabled: false,
                    //     // Ignore the default sizes that trigger responsive docking
                    breakpoint: false,
                    position: 'top-right',
                }
            },
            ...fullViewProps,
        });

        const legend = new Expand({
            view: view,
            content: new Legend({
                view: view,
            }),
        });
        view.ui.add(legend, {
            position: "top-left",
        });

        const home = new Home({
            view: view,
        });
        view.ui.add(home, {
            position: mapConfig.homeLoc,
        });
        // console.log('theView', view.map.layers.items.map(item => item.url))
        return view
    } catch (err) {
        console.log(err.message)
    }
};

export default createMapView