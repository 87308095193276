import { forwardRef } from "react"
import DataSection from "../../dataSection/DataSection"
import InsetMap from "../../insetMap/InsetMap"

const SLR = forwardRef((props, ref) => {
    const { page9, page10, page11 } = ref
    return (
        <>
            <div ref={page9}>
                <DataSection
                    title='Sea Level Rise'
                    description={<p>Sea level rise is an increase in the level of the world's oceans because of increasing global temperatures. Rising sea levels may erode shorelines, threaten coastal drinking water supplies with salt-water intrusion, and cause temporary inundation of low-lying areas during high tide. Sea level rise also contributes to higher storm surges and flooding during coastal storm events.  <a href='https://dep.nj.gov/slr/' target="_blank" rel='noreferrer'>Learn more about sea level rise in New Jersey (https://dep.nj.gov/slr/)</a>. </p>}
                >
                </DataSection>
                <DataSection

                    title='Sea Level Rise - 2ft'
                    description={<>By 2050, within the span of a 30-year mortgage, sea level rise in New Jersey will likely exceed 2 feet.  Some areas of New Jersey may already be experiencing 2 feet of flooding during high tide. <a href='https://rutgers.maps.arcgis.com/apps/mapviewer/index.html?webmap=1d2d2f4b3de747be88f92e2c1a816943' target='_blank' rel='noreferrer'>Click here to go to a interactive version of this map.</a></>}
                    childHolder='map-holder'
                // childLabel='Figure 4'
                >
                    <InsetMap portalId='1d2d2f4b3de747be88f92e2c1a816943' />
                </DataSection >
            </div>

            <DataSection
                ref={page10}
                title='Sea Level Rise - 5ft'
                description={<>By 2100, sea level rise will likely exceed 5 feet.  The New Jersey Department of Environmental Protection advises using 2100 as a planning horizon.<a href='https://rutgers.maps.arcgis.com/apps/mapviewer/index.html?webmap=e5aa3315b89e4637a84775610d9f365f' target='_blank' rel='noreferrer'>Click here to go to a interactive version of this map.</a></>}
                childHolder='map-holder'
            // childLabel='Figure 4'
            >
                <InsetMap portalId='e5aa3315b89e4637a84775610d9f365f' />
            </DataSection >
            <DataSection
                ref={page11}
                title='Sea Level Rise - 7ft'
                description={<>By 2100, with a likely sea level rise of 5 feet, some areas may experience as much as 7 feet of flooding during high tides, especially those areas that are currently experiencing 2 feet high tide flooding.<a href='https://rutgers.maps.arcgis.com/apps/mapviewer/index.html?webmap=6c86ec59573b46a39bd3555f596724fc' target='_blank' rel='noreferrer'>Click here to go to a interactive version of this map.</a></>}
                childHolder='map-holder'
            // childLabel='Figure 5'
            >
                <InsetMap portalId='6c86ec59573b46a39bd3555f596724fc' />
            </DataSection >

        </>
    )
})
export default SLR