import React, { useEffect, useRef } from 'react'
import ReportHeader from '../reportHeader/ReportHeader'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setNavLoc } from '../../features/navLocSlice'
import handleMissingCounty from '../../tools/handleMissingCounty';
// import "@esri/calcite-components/dist/components/calcite-loader";
// import { CalciteLoader } from '@esri/calcite-components-react';
import Traffic from './Traffic';
import HeatIsland from './HeatIsland';
import Ozone from './Ozone';
import PM25 from './PM25';
import HealthSites from './HealthSites';
// import StdButton from '../stdButton/StdButton';
// import handleDownloadPdf from '../../tools/handlePdfDownload';
// import { FaFilePdf } from 'react-icons/fa';
import useLocSync from '../../tools/useLocSync';
// import Diesel from './Diesel';

const EnviroExposure = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { locType, locId } = useParams()
  useLocSync(locType, locId)

  const pagesRef = {
    page1: useRef(),
    page2: useRef(),
    page3: useRef(),
    page4: useRef(),
    page5: useRef(),
    page6: useRef(),
  }

  const county = useSelector(state => state.county)
  const muni = useSelector(state => state.muni)

  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleMissingCounty(county, navigate, dispatch, setNavLoc)
  }, [county, dispatch, navigate])

  return !!county && (
    <main className='page-container'>
      <div ref={pagesRef.page1}>
        <ReportHeader title='Environmental Conditions/Exposures Associated with Negative Health Outcomes Potentially Exacerbated by Climate Change' county={county.NAME} muni={!!muni ? muni.MUN_LABEL : null}></ReportHeader>
      </div>
      {/* <div className='button-container print-hidden'>
{loading ? <CalciteLoader hidden={loading}></CalciteLoader> : <>
<p style={{ fontSize: '1.5rem' }}><em>Ensure all maps are fully loaded before downloading information.</em></p>
<StdButton handleClick={() => {
  setLoading(true)
  handleDownloadPdf(pagesRef).then(resp => setLoading(false))}} text={<><FaFilePdf /><span style={{
  margin: '1rem'
}}>DOWNLOAD PDF</span></>} solid={false} color='#007fac' />

{
 <StdButton handleClick={handleDownloadData} text={<><FaFileCsv /><span style={{ margin: '1rem' }}>DOWNLOAD DATA</span></>} solid={false} color='#005469' /> 
}
</>
}
</div> */}
      <div ref={pagesRef.page2}>
        <Traffic />
      </div>
      {/* // ** Diesel is turned off per the request of Marjorie and James because we don't have enough information to make health related claims  */}
      {/* <Diesel /> */}
      <div ref={pagesRef.page3}>
        <Ozone />
      </div>
      <div ref={pagesRef.page4}>
        <PM25 />
      </div>
      <div ref={pagesRef.page5}>
        <HeatIsland />
      </div>
      <div ref={pagesRef.page6}>
        <HealthSites />
      </div>
    </main>
  )
}

export default EnviroExposure