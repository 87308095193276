import React from 'react'
import './homepage.scss';
// import munis from '../../data/munis';
import ReportNotice from '../../components/reportNotice/ReportNotice';
// import lifeguard from '../../data/images/lifeguard.jpeg'
import { useDispatch, useSelector } from 'react-redux';
import { selectCounty } from '../../features/countySlice';
import { selectMuni, setMuni } from '../../features/muniSlice';
import LocDropDown from './LocDropDown';
import About from './About';
// import { CalciteLoader } from '@esri/calcite-components-react';
import useCounties from './useCounties';
import useMunis from './useMunis';
import { getCountyCenter } from '../../features/viewOptsSlice';
// import eph from '../../services/eph';

const Homepage = () => {

  const dispatch = useDispatch()

  const county = useSelector(state => state.county)
  const muni = useSelector(state => state.muni)

  let counties = useCounties()
  let munis = useMunis()

  // console.log('homepage', county)

  let climLoading = !!muni ? !muni?.climateData?.length === 7 : !county?.climateData?.length === 7
  let censusLoading = !!muni ? !muni?.censusData : !county?.censusData
  let ephLoading = !!muni ? !muni?.ephData : !county?.ephData

  const handleCountySelect = ({ target }) => {
    let { value } = target
    if (value === null || value.includes('Select')) {
      dispatch(selectCounty(null))
      return
    } else {
      let freshCounty = counties.find(item => item.GEOID === value)
      // console.log(freshCounty)
      dispatch(selectCounty(freshCounty))
      dispatch(setMuni(null))
    }
  }

  const handleMuniSelect = ({ target }) => {
    let { value } = target
    // console.log('value', value)
    if (value === null || value.includes('Countywide')) {
      dispatch(setMuni(null))
      dispatch(getCountyCenter(county))
      return
    } else {
      let freshMuni = munis.find(item => item.MUN_CODE === value)
      // console.log('freshMuni', freshMuni)
      dispatch(selectMuni(freshMuni))
      return
    }
  }

  // console.log(county)
  // filter the munis by county
  let filteredMunis = munis.filter(muni => muni.countyId === county?.id).sort((a, b) => a.MUN_LABEL.localeCompare(b.MUN_LABEL))

  let countiesSorted = counties.sort((a, b) => a.NAME.localeCompare(b.NAME))

  return (
    <main className='homepage-container'>
      <section className='snapshot-intro'>
        {/* <img src={lifeguard} alt='smog in a cityscape' /> */}
      </section>

      <About />

      {!county && <div><h1>Select a location</h1></div>}
      <section className='card'>
        <LocDropDown unique='GEOID' label="County" handleChange={handleCountySelect} defaultText='Select a county' optionArray={countiesSorted} valueField={'GEOID'} nameField='NAME' currVal={county?.GEOID} />
        <LocDropDown disabled={!county} label="Municipality" handleChange={handleMuniSelect} defaultText='Countywide' optionArray={filteredMunis} unique='MUN_CODE' valueField={'MUN_CODE'} nameField='MUN_LABEL' currVal={muni?.MUN_CODE} />
      </section>
      {!!county
        ? (<section className='reports' >
          <h2>Available Reports {!!muni ? (<span>for <strong>{muni.MUN_LABEL},  {county.NAME} County</strong></span>) : !!county ? (<span>for <strong>{county.NAME} County</strong></span>) : null}</h2>

          <ReportNotice title="Climate Conditions that Impact Health" path='climate-cond' loading={climLoading} />

          <ReportNotice title="Existing Health Conditions Potentially Exacerbated by Climate Change" path='exist-health' loading={ephLoading} />

          <ReportNotice title="Heat Vulnerability Index" path='hvi' />

          <ReportNotice title="Environmental Conditions/Exposures Associated with Negative Health Outcomes Potentially Exacerbated by Climate Change" path='enviro-expos' />


          <ReportNotice title="Socio-demographic Factors at Greater Risk of Negative Health Outcomes from Climate Change" path='socio-demo' loading={censusLoading} />

        </section>)
        : (
          null
        )
      }

    </main >
  )
}

export default Homepage