import { forwardRef } from "react"
import DataSection from "../../dataSection/DataSection"
import InsetMap from "../../insetMap/InsetMap"

const FloodZones = forwardRef((props, ref) => {
    const { page7 } = ref
    return (
        <div>
            <DataSection
                ref={page7}
                title='Flood Plain - FEMA Flood Zones'
                description={<>Flood zones are geographic areas that the Federal Emergency Management Agency (FEMA) has defined according to varying levels of flood risk. The most direct health impact from flooding is drowning and trauma.  Other health impacts from flooding can be the result of increased risk of exposure to contaminants such as bacteria, mold, parasites and chemical pollutants that can cause gastrointestinal, respiratory and other conditions. Any place with a 1% chance or higher chance of experiencing a flood each year is considered to have a high risk. Those areas have at least a one-in-four chance of flooding during a 30-year mortgage.<a href='https://rutgers.maps.arcgis.com/apps/mapviewer/index.html?webmap=cba402bdd0334334b6c2f2568d7e5443' target='_blank' rel='noreferrer'>Click here to go to a interactive version of this map.</a></>}
                childHolder='map-holder'
            // childLabel='Figure 3'
            >
                <InsetMap portalId='cba402bdd0334334b6c2f2568d7e5443' />
            </ DataSection>
            {/* <DataSection
                ref={page8}
                title='Flood Plain - FEMA Flood Zones + 3 feet'
                description={<p>The New Jersey Department of Environmental Protection (NJDEP) published an inland flood protection rule at the end of 2022 to ensure that new investments were well suited to current and future flood risks in New Jersey. Tropical Storm Ida flooded some places an average of 3.1 feet above the FEMA 1% chance floodplain; the NJDEP recommends adding 3 feet to the FEMA floodplain maps to assess flood risk. <a href='https://dep.nj.gov/inland-flood-protection-rule/lessons/' target="_blank" rel='noreferrer'>Learn more about how the NJDEP is integrating lessons from Ida (https://dep.nj.gov/inland-flood-protection-rule/lessons/)</a>.<br />
                    <a href='https://rutgers.maps.arcgis.com/apps/mapviewer/index.html?webmap=7697b24733da429eaa511f05b03fe528' target='_blank' rel='noreferrer'>Click here to go to a interactive version of this map.</a>
                </p>}
                childHolder='map-holder'
            >
                <InsetMap portalId='7697b24733da429eaa511f05b03fe528' />
            </ DataSection> */}
        </div>
    )
})
export default FloodZones