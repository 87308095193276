// inspired by https://www.positronx.io/react-file-upload-tutorial-with-node-express-and-multer/

import http from "../http-common";

class ClimateDataService {

    getClimateByLocation(summaryType, locationId) {
        return http.get(`/climate/${summaryType}/${locationId}`)
    }

    createClimate(data) {
        return http.post(`/climate`, data)
    }
}

export default new ClimateDataService();
