import React, { forwardRef } from 'react'
import './dataSection.scss'

const DataSection = forwardRef(({ title, description, childHolder, offspring, children, childLabel, first, ...props }, ref) => {

    return (
        <div ref={ref} className='data-section page-break' style={{ breakBefore: 'page' }}>
            <span className='data-heading'>{title}</span>
            <span className='data-description'>{description}</span>
            <span className='figure-label'>{childLabel}</span>
            <div className={childHolder}>
                {offspring}
                {children}
            </div>
        </div>
    )
})

export default DataSection