import { useEffect, useState } from "react";
import CountyDataService from '../../services/county'

const useCounties = () => {
    
    const [data, setData] = useState([])

    useEffect(() => {
        let getData = async () => {
            try {
                let response = await CountyDataService.getAllCounties()
            return response
        } catch (error) {
            console.log('useCounties getData error', error)
        }}

        getData().then(response => setData(response.data.data))
    }, [])

    return data
}

export default useCounties

