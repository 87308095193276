import { forwardRef } from "react"
import DataSection from "../../dataSection"
import InsetMap from "../../insetMap"
// import LSTMap from "./LSTMap"

const LST = forwardRef((props, ref) => {
    const { page2, page3 } = ref

    return (
        <>
            <DataSection
                ref={page2}
                title='Land Surface Temperature'
                description={<>Land Surface Temperature is the temperature of the ground. Impervious surfaces and structures such as buildings, roads, and other “built” infrastructure absorb a greater amount of solar radiation increasing land surface temperature and emit heat to a greater extent than most natural surfaces. Natural landscapes, such as trees, forests and water bodies can serve to reduce land surface temperature compared to urbanized landscapes.
                    <ul>
                        <li><a href='https://rutgers.maps.arcgis.com/home/item.html?id=27d0f981fc8340fc904e5fb91804d85a' target='_blank' rel='noreferrer'>NJDEP provides more information about the data provided in this map at this link.</a></li>
                        <li>
                            <a href='https://rutgers.maps.arcgis.com/apps/mapviewer/index.html?webmap=4d8f6d249e394357827fecd5312fc861' target='_blank' rel='noreferrer'>Click here to go to a interactive version of this map.</a>
                        </li>
                    </ul> </>}
                childHolder='map-holder'
                first={true}
            >
                <InsetMap portalId='4d8f6d249e394357827fecd5312fc861' />
            </DataSection>
            <DataSection
                ref={page3}
                // title='Land Surface Temperature'
                description={<><p>As a comparison to the land surface temperature map above, urban areas, which have a high degree of impervious surfaces, can create “islands” of higher temperatures when compared to other areas. Increased temperatures from urban heat islands can contribute to heat-related deaths and heat-related illnesses such as general discomfort, respiratory difficulties, heat cramps, heat exhaustion, and stroke.</p><p>
                    The Heat Island Analysis was created by combining an analysis of the proportion of canopy cover to impervious cover within a Census Block Group.  The Heat Island analysis is performed using the Multi-Resolution Land Characteristics Consortium (MRLC) National Land Cover Database for US Forest Service Tree Canopy Cover and Percent Developed Imperviousness datasets for the continental US. The mean percent of each of these variables was calculated for each Census Block Group in NJ, and then the ratio of canopy cover to impervious cover was calculated and normalized to the total area of both combined to generate the relative indexes.</p>
                    <a href='https://rutgers.maps.arcgis.com/apps/mapviewer/index.html?webmap=9f8adca918b0467bbcf74f5c06918e22' target='_blank' rel='noreferrer'>Click here to go to a interactive version of this map.</a></>}
                childHolder='map-holder'
            >
                <InsetMap portalId='7640ab17fb2d4f07adb1c998bd0a6899' />
            </DataSection >


        </>
    )
})

export default LST