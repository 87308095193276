import { getMuniCenter } from './viewOptsSlice'
import concatProjClim from "../components/climateConditions/Projected/concatProjClim";
import MuniDataService from '../services/muni'
import CensusDataService from '../services/census'
import EphDataService from '../services/eph'
import { muniLayer } from '../data/layers/boundaries/muniLayer';
import Query from "@arcgis/core/rest/support/Query.js";
import { getCounty } from './countySlice';
import getRainData from '../tools/getRainData';


// ** action
export const SET_MUNI = "SET_MUNI";
export const setMuni = data => ({
    type: SET_MUNI,
    payload: { data }
});
export const APPEND_MUNI = 'APPEND_MUNI';
export const appendMuni = data => ({
    type: APPEND_MUNI,
    payload: { data }
})

//** thunks */

export const getMuniByMunCode = munCode => async (dispatch, getState) => {
    let oneMuni = await MuniDataService.getByMunCode(munCode)
    let fullMuni = oneMuni?.data
    let { countyId } = fullMuni

    dispatch(getCounty(countyId))
    dispatch(selectMuni(fullMuni))
}

export const selectMuni = basicMuni => async (dispatch, getState) => {
    // console.log('basicMuni', basicMuni)
    // ** set up all of the data ahead of time 
    if (basicMuni === null) {
        dispatch(setMuni(null))
    } else {
        dispatch(setMuni(basicMuni))

        let county = getState().county
        let censusYear = process.env.REACT_APP_CENSUS_YEAR

        // const countyCode = String(county.GEOID.substring(2)).padStart(3, '0')

        //** get muni center */

        dispatch(getMuniCenter(basicMuni))

        //** check for other data sets */
        let oneMuni = await MuniDataService.getOneMuni(basicMuni.id)
        let fullMuni = oneMuni.data.data
        dispatch(setMuni(fullMuni))

        //** coastal */
        if (fullMuni.coastal === null) {
            console.log('this muni needs coastal data', fullMuni.coastal)
            const query = new Query({
                where: `MUN_CODE = '${fullMuni.MUN_CODE}'`,
                outFields: ['*'],
            })

            let results = await muniLayer.queryFeatures(query)

            let coastal = results?.features?.[0]?.attributes?.COASTAL
            dispatch(appendMuni({ coastal }))
        } else {
            console.log('this muni has coastal classification', fullMuni.coastal)
        }
        // ** climate data
        if (!fullMuni.climateData.length) {

            //** concatProjClim is updating the database with the data */
            let projClim = await concatProjClim(basicMuni.MUN_CODE, basicMuni.id, true)
            dispatch(appendMuni({ climateData: projClim }))
        } else {
            console.log('climate data exists', fullMuni.climateData)
        }
        //** rain data */
        console.log('is there rain data', fullMuni.rainData)
        if (!fullMuni.rainData) {
            //** getRainData is updating the database with the data */
            let freshRain = await getRainData(county, fullMuni)
            console.log('freshRain', freshRain)
            dispatch(appendMuni({ rainData: freshRain }))
        } else {
            console.log('rain data exists', fullMuni.rainData)
        }
        //** census data */
        if (!fullMuni.censusData || fullMuni.censusData.year.toString() !== censusYear.toString()) {
            let censusData = await CensusDataService.getCensus('muni', basicMuni.id, censusYear);
            // console.log('censusData', censusData.data)
            dispatch(appendMuni({ censusData: censusData.data }))
        } else {
            console.log('census data exists', fullMuni.censusData)
        }
        //** eph public health data */
        if (!fullMuni.ephData) {
            let ephData = await EphDataService.getEph('muni', basicMuni.id)
            dispatch(appendMuni({ ephData: ephData.data.data }))
        } else {
            console.log(`ephData exists`, fullMuni.ephData)
        }
    }
}

// ** reducer
export const muni = (state = null, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_MUNI: {
            console.log('payload', payload)
            let { data } = payload
            if (data === undefined) {
                return null
            }
            return data
        }
        case APPEND_MUNI: {
            let { data } = payload
            let freshMuni = { ...state, ...data }
            console.log('appended muni', freshMuni)
            return freshMuni
        }
        default: {
            return state;
        }
    }
}
