const prepHeatWaveData = data => {
    let threshold = ''
    let numAnnual = ['Mean Number of Annual Heat Waves']
    let meanDuration = ['Mean Days of Heat Wave Duration']
    let maxDuration = ['Maximum Days of Heat Wave Duration']

    data.forEach(item => {
        if (item["10% Annual Heatwave Number"] !== "N/A") {
            // console.log('numAnnual')
            numAnnual.push(`${item['Annual Mean Heatwave Number']} (${item["10% Annual Heatwave Number"]} - ${item["90% Annual Heatwave Number"]})`)
        } else {
            numAnnual.push(item[`Annual Mean Heatwave Number`].toString())
        }

        if (item["10% Annual Heatwave Duration (Days)"] !== "N/A") {
            // console.log('meanDuration')
            meanDuration.push(`${item['Annual Mean Heatwave Duration (Days)']} (${item["10% Annual Heatwave Duration (Days)"]} - ${item["90% Annual Heatwave Duration (Days)"]})`)
        } else {
            meanDuration.push(item[`Annual Mean Heatwave Duration (Days)`].toString())
        }


        if (item["10% Annual Max Heatwave Duration (Days)"] !== "N/A") {
            maxDuration.push(`${item['Annual Max Heatwave Duration (Days)']} (${item["10% Annual Max Heatwave Duration (Days)"]} - ${item["90% Annual Max Heatwave Duration (Days)"]})`)
        } else {
            maxDuration.push(item[`Annual Max Heatwave Duration (Days)`].toString())
        }


        threshold = item["Threshold (˚F)"]
    })


    // the order that it comes out in is historical [0], moderate mid[1], high mid[2], moderate late[3], high late[4].  Need to reorder to be historical, moderate mid, moderate late, high mid, high late (0,1,3,2,4)
    // const swapElements = (array, index1, index2) => {
    //     let temp = array[index1];
    //     array[index1] = array[index2];
    //     array[index2] = temp;
    // };

    // [numAnnual, meanDuration, maxDuration].forEach(array => {
    //     swapElements(array, 2, 3)
    // })



    return { numAnnual, meanDuration, maxDuration, threshold }
}

export default prepHeatWaveData