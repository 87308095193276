import DataSection from "../../dataSection/DataSection"
import countyData from './ozoneData/countyOzone.json'
import muniData from './ozoneData/muniOzone.json'
import { useSelector } from "react-redux";
import Chart from '../../Chart'

const Ozone = () => {
    const county = useSelector(state => state.county)
    const muni = useSelector(state => state.muni)

    let filteredData
    if (!!muni) {
        filteredData = muniData.find(item => parseInt(item.MUN_CODE) === parseInt(muni.MUN_CODE))
    } else {
        filteredData = countyData.find(item => parseInt(item.CountyFIPS) === parseInt(county.GEOID))
    }

    let categories = !!muni ? Object.keys(filteredData).filter(item => item.includes('ThreeYr')).map(item => item.split('Avg')[1]) : Object.keys(filteredData).filter(item => item.includes('ThreeYr')).map(item => item.split("_")[1])
    let data = Object.entries(filteredData).filter(item => item[0].includes('ThreeYr')).map(item => item[1])

    let chartOptions = {
        margin: 0,
        title: {
            text: `Days per year over ozone standard of 0.07ppm`,
        },

        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: 'days'
            },

        },
        tooltip: {
            crosshairs: true,
            shared: true,
        },
        series: [
            {
                name: "Ozone Days",
                type: "line",
                data: data,
                color: '#007fac'
            },
        ],
    }

    return (
        <DataSection title='Ozone'
            description={<>Ground-level ozone, often called smog, forms when gases emitted from smokestacks and tailpipes (Nitrogen oxides and volatile organic compounds) chemically react in the presence of sunlight. Clime change creates conditions, such as increased heat, which exacerbates the production of ground-level ozone and increases the risk of unhealthy ozone levels. High levels of ozone can irritate lungs and worsen respiratory conditions including asthma. Information on Ozone is presented as a rolling 3-year average of days per year over the air quality standard. Data on days over the ozone air quality standard is provided by the US Environmental Protection Agency through their <a style={{ color: 'black' }} href='https://www.epa.gov/AirToxScreen' target={'_blank'} rel="noreferrer">AirToxScreen portal</a> and the <a href='https://ephtracking.cdc.gov/DataExplorer/' target="_blank" rel="noreferrer" >CDC Public Health Data Explorer</a>.  Read more about ozone in New Jersey <a href='https://dep.nj.gov/wp-content/uploads/ej/docs/njdep-ej-technical-guide.pdf#page=16' target={'_blank'} rel="noreferrer" >here</a>. </>}
            childHolder='table-holder'
            // childLabel={'Figure 2'}
        >
            <Chart chartOptions={chartOptions} />
        </DataSection>
    )
}
export default Ozone