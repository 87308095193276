import React, { forwardRef } from 'react'
import './reportHeader.scss'

const ReportHeader = forwardRef(({ title, county, muni, ...props }, ref) => {
  let months = ['January', "February", "March", "April", "May", "June", 'July', "August", "September", "October", "November", "December"]
  let monthIndex = new Date().getMonth()
  let month = months[monthIndex]
  let day = new Date().getDate()
  let year = new Date().getFullYear()

  let date = `${month} ${day}, ${year}`


  return (
    <>
      <div className='header-container' ref={ref}>
        <div className='report-title'>
          {title}
        </div>
        <div className='location'>
          {!!muni ? muni : <span>{county} County</span>}
        </div>
        {!!muni
          ? (
            <div className='sub-location'>
              {!!muni ? <span>{county} County</span> : null}
            </div>)
          : null}
      </div>
      <div className='report-date'><em>{date}</em></div>
    </>
  )
})

export default ReportHeader