// inpired by https://blog.cetindere.de/integrate-google-analytics-into-your-react-web-app/
import { useEffect } from "react";
import ReactGA from "react-ga";

export const useAnalyticsEventTracker = (category = "Blog Category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};

const addGaScript = () => {
  const gaScript = document.createElement("script");
  gaScript.async = true;
  gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING}`;
  document.body.append(gaScript);
};

const createWindowGTag = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag;
  gtag("js", new Date());

  gtag("config", process.env.REACT_APP_GA_TRACKING);
};

export const useGoogleAnalytics = () => {
  // console.log('using google analytics', process.env.REACT_APP_GA_TRACKING.substring(4,))
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
  addGaScript();
  createWindowGTag();

  window.gtag("config", process.env.REACT_APP_GA_TRACKING, {
    path_title: window.location.pathname,
    path_page: window.location.pathname,
  });

  useEffect(() => {
    // console.log('pageview', window.location)
    ReactGA.pageview(window.location.pathname);
  }, []);

  return;
};

<script
  async
  src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING}`}
></script>;
