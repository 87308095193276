// inspired by https://www.positronx.io/react-file-upload-tutorial-with-node-express-and-multer/

import http from "../http-common";

class MuniDataService {

    getAllMunis() {
        return http.get(`/muni`)
    }
    getOneMuni(id) {
        return http.get(`/muni/${id}`)
    }
    getByMunCode(munCode) {
        return http.get(`/muni/byCode/${munCode}`)
    }
}

export default new MuniDataService();
