import "@esri/calcite-components/dist/components/calcite-loader";
import { forwardRef, useEffect, useRef, useState } from "react";
import createMapView from "../../tools/createMapView";
import './insetMap.scss';
import { useSelector } from "react-redux";
import Legend from "@arcgis/core/widgets/Legend";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import { CalciteLoader } from "@esri/calcite-components-react";

const InsetMap = forwardRef(({ portalId, ...props }, ref) => {
  const mapRef = useRef(null)
  const legendRef = useRef(null)
  const imgRef = useRef(null)
  const [imageSrc, setImgSrc] = useState()


  const viewOpts = useSelector(state => state.viewOpts)
  // console.log('viewOpts', viewOpts)

  // const [isFrozen, setIsFrozen] = useState(true)
  // const [isLoading, setIsLoading] = useState(true)


  let freshMapOpts = { ...viewOpts.map, portalItem: { id: portalId } }
  let { zoom, extent } = viewOpts
  let freshViewOpts = { zoom, extent }

  // ** Create the View
  const theView = createMapView(freshMapOpts, freshViewOpts)

  let staticLegend = new Legend({
    view: theView,
    style: {
      type: "card",
      layout: "side-by-side"
    }
  })
  // const activeLegend = new Expand({
  //   view: theView,
  //   content: new Legend({
  //     view: theView,
  //   }),
  // });
  // const home = new Home({
  //   view: theView,
  // });

  // const print = new Expand({
  //   view: theView,
  //   content: new Print({
  //     view: theView,
  //     printServiceUrl: "https://terrene.njaes.rutgers.edu:6443/arcgis/rest/services/Tools/ExportWebMap/GPServer/Export%20Web%20Map",
  //     allowedLayouts: [
  //       "letter-ansi-a-portrait",
  //       "letter-ansi-a-landscape",
  //     ],
  //   })
  // })


  useEffect(() => {
    if (legendRef.current && legendRef.current.children.length < 1) {
      staticLegend.container = legendRef.current
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {

    theView.ui.empty('top-left')
    // theView.ui.add(staticLegend, 'top-left')
    // freeze the view
    theView.on('mouse-wheel', (evt) => {
      evt.stopPropagation()
    })


    theView.container = mapRef.current

    // eslint-disable-next-line
  }, [])


  reactiveUtils.whenOnce(() => !theView.updating).then(() => {
    // Take a screenshot at the same resolution of the current view
    theView.takeScreenshot().then((screenshot) => {
      // console.log('screenshot', screenshot)
      setImgSrc(screenshot.dataUrl)
    });
  });


  // console.log('legend', legendRef.current.innerHTML)

  return (
    <div ref={ref} className={'inset-map page-break'}>
      <div ref={legendRef}></div>
      <CalciteLoader hidden={!!imageSrc}></CalciteLoader>
      <div style={{ position: 'relative' }}>

        <img style={{ zIndex: 1 }} src={imageSrc} alt='map of new jersey' ref={imgRef} />
        <div style={{
          visibility: 'hidden',
          position: 'absolute', top: '0px', zIndex: 0
        }} className={`live-map print-hidden`} ref={mapRef}></div>
      </div>
    </div>
  );
})
export default InsetMap;
