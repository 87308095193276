// actions
export const SET_NAV_LOC = "SET_NAV_LOC";
export const setNavLoc = data => ({
  type: SET_NAV_LOC,
  payload: { data },
});

export const navLoc = (state = 'all', action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_NAV_LOC: {
      // console.log('changing nav loc')
      const { data } = payload
      return data
    }
    default: {
      return state;
    }
  }
};
