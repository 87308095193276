import DataSection from "../../dataSection/DataSection"
import InsetMap from "../../insetMap/InsetMap"

const HeatIsland = () => {
    return (
        <DataSection
            title='Heat Island Analysis with Overburdened Communities'
            description={<>A heat island is a geographic location that is significantly warmer than the surrounding region due to factors such as a high degree of developed area and reduced natural environments, such as forests). Heat islands can increase daytime temperatures, reduce nighttime cooling, and cause higher air-pollution levels. These conditions can contribute to heat-related deaths and heat-related illnesses such as respiratory difficulties, dehydration, heat exhaustion, and heat stroke.<a href='https://rutgers.maps.arcgis.com/apps/mapviewer/index.html?webmap=9f8adca918b0467bbcf74f5c06918e22' target='_blank' rel='noreferrer'>Click here to go to a interactive version of this map.</a></>}
            childHolder='map-holder'
        // childLabel={'Figure 5'}
        >
            <InsetMap portalId={'0d95dc8803ba4546896aed8296834314'} />
        </DataSection>
    )
}
export default HeatIsland