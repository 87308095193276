const handleMissingCounty = (county, navigate, dispatch, setNavLoc) => {
    // console.log('handling missing county', !!county)
    if (!!county) {
        return
    } else {
        navigate('/');
        dispatch(setNavLoc('all'))
        alert('Location not found. Please select a location from the dropdown.')
    }
}

export default handleMissingCounty