const calculateHouseData = data => {
    // console.log('data',data)
    let output = {
        bef1980: { label: 'Houses built before 1980', num: 0, pct: 0 },
        bef1960: { label: 'Houses built before 1960', num: 0, pct: 0 },
        crowded: { label: 'Overcrowding', num: 0, pct: 0 },
        noNet: { label: 'No Internet', num: 0, pct: 0 },
        oneParent: { label: 'Single Parent Households', num: 0, pct: 0 },
        renters: { label: 'Renter Occupied Units', num: 0, pct: 0 },
        noVeh: { label: 'No Vehicle', num: 0, pct: 0 },
    }


    output.bef1980.num = (Number(data.B25034_007E) + Number(data.B25034_008E) + Number(data.B25034_009E) + Number(data.B25034_010E) + Number(data.B25034_011E)).toLocaleString()
    output.bef1980.pct = ((Number(data.B25034_007E) + Number(data.B25034_008E) + Number(data.B25034_009E) + Number(data.B25034_010E) + Number(data.B25034_011E)) / Number(data.B25034_001E) * 100).toFixed(0) + "%"

    output.bef1960.num = (Number(data.B25034_009E) + Number(data.B25034_010E) + Number(data.B25034_011E)).toLocaleString()
    output.bef1960.pct = ((Number(data.B25034_009E) + Number(data.B25034_010E) + Number(data.B25034_011E)) / Number(data.B25034_001E) * 100).toFixed(0) + "%"

    output.crowded.num = (Number(data.B25014_005E) + Number(data.B25014_006E) + Number(data.B25014_007E) + Number(data.B25014_011E) + Number(data.B25014_012E) + Number(data.B25014_013E)).toLocaleString()
    output.crowded.pct = (Number(data.B25014_005E) + Number(data.B25014_006E) + Number(data.B25014_007E) + Number(data.B25014_011E) + Number(data.B25014_012E) + Number(data.B25014_013E)) ? ((Number(data.B25014_005E) + Number(data.B25014_006E) + Number(data.B25014_007E) + Number(data.B25014_011E) + Number(data.B25014_012E) + Number(data.B25014_013E)) / Number(data.B25014_001E) * 100).toFixed(0) + '%' : '0%'

    output.noNet.num = Number(data.B28002_013E).toLocaleString()
    output.noNet.pct = (Number(data.B28002_013E) / Number(data.B28002_001E) * 100).toFixed(0) + "%"

    output.oneParent.num = (Number(data.B11005_006E) + Number(data.B11005_007E)).toLocaleString()
    output.oneParent.pct = ((Number(data.B11005_006E) + Number(data.B11005_007E)) / Number(data.B11005_002E) * 100).toFixed(0) + "%"

    output.renters.num = Number(data.B25003_003E).toLocaleString()
    output.renters.pct = (Number(data.B25003_003E) / Number(data.B25003_001E) * 100).toFixed(0) + '%'

    output.noVeh.num = Number(data.B08201_002E).toLocaleString()
    output.noVeh.pct = (Number(data.B08201_002E) / Number(data.B08201_001E) * 100).toFixed(0) + "%"

    return output

}

export default calculateHouseData