import { useSelector } from "react-redux"
import DataSection from "../../dataSection/DataSection"
import DataTable from "../../dataTable/DataTable"
import { useEffect } from "react"
import { useState } from "react"
import getRainData from "../../../tools/getRainData"

const ExtremeRain = () => {
    // console.log('renderng extreme rain')
    // database stores rain data as a {MUN_CODE, data}

    const muni = useSelector(state => state.muni)
    const county = useSelector(state => state.county)

    const [rainData, setRainData] = useState(null)
    const [tableData, setTableData] = useState(null)

    // formatRainData wants the actual data, not the db object containing the mun code
    const formatRainData = (freshRain) => {
        // console.log('formatting rain data', freshRain)
        freshRain = freshRain.data
        if (!freshRain) {
            return ['data error']
        }
        // ** set up the data structre for the table **
        let output = [['50% chance'], ['20% chance'], ['10% chance'], ['4% chance'], ['2% chance'], ['1% chance']]
        output.forEach((item, j) => {
            // ** add current/historical data ** 
            Object.values(freshRain.Atlas14[`24hr_mean`]).forEach((value, i) => {
                // ** the 6th is the regression params, we don't want those **
                if (j !== i) {
                    return
                }
                item.push(value.toFixed(2))
            })
            // ** add mod, mid data **
            Object.values(freshRain[`rcp45_2020-2069`][`24hr`]).forEach((value, i) => {
                // ** the 6th is the regression params, we don't want those **
                if (j !== i) {
                    return
                }
                item.push(value.toFixed(2))
            })

            // ** add mod, late data **
            Object.values(freshRain[`rcp45_2050-2099`][`24hr`]).forEach((value, i) => {
                // ** the 6th is the regression params, we don't want those **
                if (j !== i) {
                    return
                }
                item.push(value.toFixed(2))
            })
            // ** add high, mid data **
            Object.values(freshRain[`rcp85_2020-2069`][`24hr`]).forEach((value, i) => {
                // ** the 6th is the regression params, we don't want those **
                if (j !== i) {
                    return
                }
                item.push(value.toFixed(2))
            })
            // ** add high, late data **
            Object.values(freshRain[`rcp85_2050-2099`][`24hr`]).forEach((value, i) => {
                // ** the 6th is the regression params, we don't want those **
                if (j !== i) {
                    return
                }
                item.push(value.toFixed(2))
            })
        })
        let years = ['Years', '(1981-2010)', 'Mid-Century (2036-2065)', 'Late-Century (2070-2099)', 'Mid-Century (2036-2065)', 'Late-Century (2070-2099)']

        output.unshift(years)
        // console.log('extreme rain', output)
        return output
    }
    useEffect(() => {
        if (!muni?.rainData?.data) {
            getRainData(county, muni).then(data => {
                // console.log('db rain data', data)
                setRainData(data)
            })

        } else if (muni?.rainData) {
            // console.log('muni rain data', muni.rainData)
            setRainData(muni.rainData)
        } else {
            console.log('no rain data', muni)
        }

    }, [muni, county])


    useEffect(() => {
        if (rainData) {
            setTableData(formatRainData(rainData))

        }
    }, [rainData])

    let caption = <span> Rainfall amount in inches given storms of various annual probabilities.</span>
    let tableColumns = [
        '',
        'Baseline',
        {
            colspan: 2, name: <span>Moderate Emissions (RCP 4.5)
                {/* Change from Baseline */}
            </span>
        },
        {
            colspan: 2, name: <span>High Emissions (RCP 8.5)
                {/* Change from Baseline */}
            </span>
        },
    ]

    return (
        <DataSection
            title='Extreme Precipitation'
            description={<><p>Extreme precipitation refers to events where the amount of rainfall or snow substantially exceeds what is considered a normal amount for a location. What is considered extreme varies by location and season, and climate change is expected to increase the frequency and intensity of extreme rainfall events. Heavy rainfall can cause flooding, the second deadliest weather-related hazard in the U.S., with flash flooding being especially deadly. After an extreme rainfall event, waterborne disease outbreaks are more likely. Water damage and mold in homes after an extreme rainfall event can worsen indoor air quality, exacerbating asthma and other respiratory illnesses.</p><p><strong>What about 100-year storms?</strong> Several "100-year storms" have occurred in the State of New Jersey and many other places in the country in the past few years.  The term 100-year storm means that there is a 1 in 100 chance of a storm occurring in a given year, or a 1% chance.  However, the amount of rainfall that previously had a 1% chance of falling now has a greater chance; it is no longer a 100-year storm.  Because the chances of a storm occurring are changing, and using terms like 500-year storm gives people a false sense of safety ('we just had a 500-year storm so I won't see that again in my lifetime'), organizations like FEMA and NJDEP are moving away from using that terminology.</p></>}
            childHolder='table-holder'
            childLabel='Table 3'
        >
            {tableData ? <DataTable data={tableData} header={tableColumns} topCaption={caption} /> : <span>There was an error retrieving rain data for this location.</span>}
        </DataSection>
    )
}
export default ExtremeRain