import { useSelector } from "react-redux"
import DataSection from "../../dataSection/"
import DataTable from "../../dataTable/DataTable";
import { forwardRef } from "react";

const Projected = forwardRef((props, ref) => {
    const { page5 } = ref

    const county = useSelector(state => state.county)
    const muni = useSelector(state => state.muni)

    let climateData = !!muni ? muni.climateData : county.climateData

    // console.log('projected', climateData)
    //** table expects data to be formatted in an array of arrays or objects *
    let years = ['Years', '1981-2010', '2030', '2060', '2090', '2030', '2060', '2090',]
    let gt95 = climateData.map(item => {
        // console.log('item', item)
        if (item.gt95_min === item.gt95_max) {
            return `${item.year === 2010 ? '' : '+'} ${Number(item.gt95_max).toFixed(0)}`
        }
        return `${item.year === 2010 ? '' : '+'} ${Number(item.gt95_min).toFixed(0)} - ${Number(item.gt95_max).toFixed(0)}`
    })

    let cdd = climateData.map(item => {
        if (item.cdd_min === item.cdd_max) {
            return `${item.year === 2010 ? '' : '+'} ${Number(item.cdd_max.toFixed(0)).toLocaleString()}`
        }
        return `${item.year === 2010 ? '' : '+'} ${Number(item.cdd_min.toFixed(0)).toLocaleString()} - ${Number(item.cdd_max.toFixed(0)).toLocaleString()}`
    })
    let maxt = climateData.map(item => {
        if (item.maxt_min === item.maxt_max) {
            return `${item.year === 2010 ? '' : '+'} ${Number(item.maxt_max).toFixed(0)}`
        }
        return `${item.year === 2010 ? '' : '+'} ${Number(item.maxt_min).toFixed(0)} - ${Number(item.maxt_max).toFixed(0)}`
    })
    gt95.unshift('Days greater than 95˚F')
    cdd.unshift('Cooling Degree Days')
    maxt.unshift('Max Temp July')
    let tableData = [years, gt95, maxt, cdd]

    let tableColumns = [
        '',
        'Baseline',
        { colspan: 3, name: <span>Moderate Emissions (RCP 4.5) Change from Baseline</span> },
        { colspan: 3, name: <span>High Emissions (RCP 8.5) Change from Baseline</span> },
    ]

    return !!climateData && (
        <DataSection
            ref={page5}
            title='Projected Climate Data'
            description={<div style={{ fontSize: '1.6rem' }}>
                <a style={{ lineHeight: "5rem" }} href='https://climatedashboards.rutgers.edu/' target="_blank" rel="noreferrer">View an interactive dashboard that explores climate data at the state level.</a>


                <p> <span style={{ display: 'block', fontWeight: 'bold' }}>RCPs</span><br />Representative Concentration Pathways (RCPs) are greenhouse gas emission scenarios adopted by the IPCC to describe potential climate futures. All RCPs are considered plausible depending on greenhouse gases emissions in the years to come. Emissions in RCP 4.5 peak around 2040, then decline. In RCP 8.5, emissions continue to rise throughout the 21st century.</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '3rem', borderBottom: '1px solid gray' }}></div>
                </div>
                <p>
                    <span style={{ display: 'block', fontWeight: 'bold' }}>Days above 95˚F</span><br />
                    The total number of days per year with maximum temperature above 95°F is an indicator of how often very hot conditions occur. Depending upon humidity, wind, and access to air-conditioning, humans may feel very uncomfortable or experience heat stress or other heat-related illness on very hot days. Hot days also stress plants and animals as well as infrastructure. Increased demand for cooling can stress energy infrastructure. A baseline is compiled from a long-term average of observations for a particular variable. In this case the baseline for the number of days per year in NJ with maximum temperature above 95°F was created over a 30-year period from 1981 to 2010.</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '3rem', borderBottom: '1px solid gray' }}></div>
                </div>
                <p>
                    <span style={{ display: 'block', fontWeight: 'bold' }}>Cooling Degree Days</span><br />
                    The number of cooling degree days per year reflects the amount of energy people use to cool buildings during the warm season. For this calculation a standard base temperature of 65°F is utilized. On a day when the average outdoor temperature is 85°F, reducing the indoor temperature by 20 degrees over 1 day requires 20 degrees of cooling multiplied by 1 day, or 20 cooling degree days. Utility companies use cooling degree days to estimate the annual amount of energy people will use to cool buildings. A baseline is compiled from a long-term average of observations for a particular variable. In this case the baseline for the change in the number of cooling degree days during the warm season in NJ was created over a 30-year period from 1981 to 2010.</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '3rem', borderBottom: '1px solid gray' }}></div>
                </div>
                <p>
                    <span style={{ display: 'block', fontWeight: 'bold' }}>Maximum Temperature in July</span><br />
                    July is historically the hottest month in New Jersey, and how maximum July temperatures may change under moderate and high emission scenarios by 2030, 2060, and 2090 can inform heat and health planning. Higher maximum temperatures could exceed thresholds of comfort, increasing the chances of heat related illnesses, energy demand, and deleterious impacts on plants and animals. The maximum July temperatures baseline is compiled from a long-term average over a 30-year period from 1981 to 2010.</p><p><em>
                        Climate data provided by the Northeast Regional Climate Center at Cornell University through their Applied Climate Information System <a href='https://www.rcc-acis.org/' target={'_blank'} rel='noreferrer'>(ACIS, https://www.rcc-acis.org)</a> and The Localized Constructed Analog downscaling projections developed by the Scripps Institution of Oceanography <a href='https://loca.ucsd.edu/' target={'_blank'} rel='noreferrer'>(https://loca.ucsd.edu)</a> were accessed through ACIS.</em></p>
            </div>}
            childHolder='table-holder'
            childLabel='Table 2'
        >
            {/* {console.log('projected', projClimData)} */}
            <DataTable data={tableData} header={tableColumns} />

        </DataSection>
    )
})
export default Projected