import React from 'react'
import DataSection from '../../dataSection/DataSection'
import { useSelector } from 'react-redux'
import Chart from '../../Chart/Chart'
import DataTable from '../../dataTable/DataTable'
import calculateHouseData from './calculateHouseData'

const HouseDemo = () => {

  const muni = useSelector(state => state.muni)
  const county = useSelector(state => state.county)

  let censusData = !!muni ? muni.censusData : county.censusData

  let houseDemo = calculateHouseData(censusData)
  // console.log('houseDemo', censusData) B25034_001E
  let caption = <span>Total households: {Number(censusData.B25034_001E).toLocaleString()}</span>
  let tableHeader = ["", 'Household Estimate', 'Household Percent']
  let tableData = Object.values(houseDemo)
  let categories = tableData?.map(item => Object.values(item)[0])
  let chartData = tableData?.map(item => Number(Object.values(item)[2].slice(0, Object.values(item)[2].length - 1)))
  let chartOptions = {
    chart: {

      type: 'column',
      width: 800,
      // margin: 0,
    },
    title: {
      text: ``,
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: 'percent'
      },

    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    series: [

      {
        name: "% of households",
        type: "column",
        data: chartData,
        color: '#007fac'
      },
    ],
  }

  return (
    <DataSection title={`Household Proportional Demographics ${process.env.REACT_APP_CENSUS_YEAR}`}
    >
      <Chart chartOptions={chartOptions} />
      <DataTable data={tableData} header={tableHeader} topCaption={caption} bottomCaption={<em>Crowding is defined here as the number of households that have more people than rooms.</em>} />
    </DataSection>
  )
}

export default HouseDemo