import { muniLayer } from "../data/layers/boundaries/muniLayer";
import { countyLayer } from "../data/layers/boundaries/countyLayer";
// import { appendMuni } from "./muniSlice";
// import { appendCounty, setCounty } from "./countySlice";
// import CountyDataService from '../services/county'

// ** actions
export const SET_VIEW_EXTENT = "SET_VIEW_EXTENT";
export const setViewExtent = data => ({
    type: SET_VIEW_EXTENT,
    payload: { data }
});

// don't want to add layers to the overall map state

// ** thunks
export const getMuniCenter = muni => {
    return async (dispatch) => {
        try {
            let results = await muniLayer.queryFeatures({ where: `MUN_CODE = '${muni.MUN_CODE}'`, outFields: ['*'], returnGeometry: true })
            // console.log('muni extent', results.features[0])
            dispatch(setViewExtent(results.features[0].geometry.extent))
            // dispatch(appendMuni(results.features[0].attributes))

        } catch (err) {
            alert('getMuniCenter.error', err)
        }
    }
};

export const getCountyCenter = county => {
    return async (dispatch) => {
        try {
            let query = {
                where: `FIPSSTCO = '${county.GEOID}'`,
                outFields: ['*'],
                returnGeometry: true
            }
            let results = await countyLayer.queryFeatures(query)
            dispatch(setViewExtent(results.features[0].geometry.extent))
            // let updatedCounty = await CountyDataService.updateCounty(county.id, { viewExtent: results.features[0].geometry.extent })
            // console.log('getcounycenter', updatedCounty)
            // dispatch(appendCounty({viewExtent: results.features[0].geometry.extent}))
        } catch (err) {
            console.log('getCountyCenter.error', err)
        }
    }
};


// ** reducer
const initialState = {
    center: [-74.5, 40.9],
    zoom: 2,
    map: {
        basemap: 'topo-vector',
        // layers: [muniLayer, countyLayer]
    }
}

export const viewOpts = (state = initialState, action) => {
    const { type, payload } = action;

    // console.log(type, payload)

    switch (type) {
        case SET_VIEW_EXTENT: {
            let { data } = payload
            if (state.center) {
                delete state.center
            }
            return { ...state, extent: data, zoom: 2 }
        }

        default: {
            return state;
        }
    }
}