import { useSelector } from "react-redux"
import DataSection from "../../dataSection"
import DataTable from "../../dataTable"
import data from './heatwaveAnalysis/heatwaveData.json'
import prepHeatWaveData from './prepHeatWaveData'
import { forwardRef } from "react"
import Chart from '../../Chart'

const HeatWave = forwardRef((props, ref) => {
    const { page4 } = ref
    // console.log('rendering HeatWave')
    // ** as of March, 2023 this data is available at the county level only  
    const county = useSelector(state => state.county)

    // console.log('heatwave county', county)

    if (!county.NAME) {
        return <div>Loading...</div>
    }

    // ** filter by county and rearrange the datasets so that rcps are together.
    let filteredData = data.filter(item => item.County.toLowerCase() === county.NAME.toLowerCase()).sort((a, b) => a.Dataset.localeCompare(b.Dataset))

    let { numAnnual, maxDuration, meanDuration, threshold } = prepHeatWaveData(filteredData)


    let caption = `Mean historical and projected heatwave characteristics for ${county.NAME} County. The threshold temperature for a heatwave in ${county.NAME} County is ${threshold}˚F. Projected values are presented with 10th-90th percentile interval in parentheses.`

    

    let tableColumns = [
        '',
        'Baseline',
        {
            colspan: 2, name: <span>Moderate Emissions (RCP 4.5)</span>
        },
        {
            colspan: 2, name: <span>High Emissions (RCP 8.5)</span>
        },
    ]

    let years = ['Years', '(1981-2010)', 'Mid-Century (2036-2065)', 'Late-Century (2070-2099)', 'Mid-Century (2036-2065)', 'Late-Century (2070-2099)']

    let tableData = [years, numAnnual, meanDuration, maxDuration]

    return (
        <DataSection
            ref={page4}
            title={<><span style={{ fontSize: '2.5rem' }}>County-Wide Heat Wave Duration</span></>}
            description={<>
                <p>Heat wave duration is the average length of a heat wave in days. Longer heat waves are projected to occur more frequently in the New Jersey region. The length of a heat wave can affect the severity of health outcomes including dehydration, heat stroke, cardiovascular and respiratory conditions, and kidney disorders.
                    County level data is provided for municipalities as model projections do not provide any meaningful variation at levels sub-county levels. <a href='https://njhazadapt.rutgers.edu/files/metadata/Heatwave_Dataset_Metadata_v4.pdf' target="_blank" rel='noreferrer'>View metadata and methodology (https://njhazadapt.rutgers.edu/files/metadata/Heatwave_Dataset_Metadata_v4.pdf).</a></p>
                <p>A moderate emissions scenario is defined as green house gas emissions that peak around 2040, then decline. A high emissions scenario is defined as green house gas emissions that continue to rise throughout the 21st century.</p>
            </>}
            childLabel='Table 1'
            childHolder='table-holder'
        >
            <Chart />
            <DataTable data={tableData} header={tableColumns} topCaption={caption} />
        </DataSection>
    )
})
export default HeatWave